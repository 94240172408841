var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "addRep",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": "",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.checkRep
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addRep-header text-center"
        }, [_c('h4', [_vm._v("Add Rep")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [Object.keys(_vm.downlineList).length ? _c('div', {
    attrs: {
      "id": "tree"
    }
  }, [_c('div', {
    class: "branch branch-".concat(_vm.downlineList.agent_id)
  }, [_c('div', {
    staticClass: "entry message-downline-tree"
  }, [_c('send-message-rep-tree-card', {
    attrs: {
      "rep": _vm.downlineList,
      "parentUplineId": _vm.downlineList.agent_id
    },
    on: {
      "onCheckAllDownline": _vm.onCheckAllDownlineHandler,
      "onRemoveDownlines": _vm.removeRelatedDownlines
    }
  }), _vm.downlineList.downline ? [_c('send-message-rep-tree-node', {
    attrs: {
      "downline": _vm.downlineList.downline,
      "upline": _vm.downlineList,
      "all-selected-reps": _vm.allSelectedReps
    },
    on: {
      "update:allSelectedReps": function updateAllSelectedReps($event) {
        _vm.allSelectedReps = $event;
      },
      "update:all-selected-reps": function updateAllSelectedReps($event) {
        _vm.allSelectedReps = $event;
      },
      "onUpdateSelectedReps": _vm.newSelectedReps
    }
  })] : _vm._e()], 2)])]) : _c('p', {
    staticClass: "text-center"
  }, [_vm._v("No Record Found")])]), _vm.sendRepMessageSelectedReps.length > 0 ? _c('div', {
    staticClass: "form-group text-center mb-3 mt-3"
  }, [_c('b-button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.selectReps
    }
  }, [_vm._v(" Select ")])], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }