var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-12 col-xl-12 col-lg-12 col-md-12"
  }, [_c('div', {
    staticClass: "sendMessage-Formcard"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "sendMessageContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.sendMessage);
            }
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-xl-4 d-flex justify-content-end"
        }, [_c('div', {
          staticClass: "message-display-section"
        }, [_c('b-modal', {
          ref: "previewMessage",
          attrs: {
            "hide-footer": true,
            "id": "previewMessage",
            "centered": "",
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "popUp-header",
                staticStyle: {
                  "border-bottom": "0px !important"
                }
              }, [_c('h4', {
                staticClass: "header-popup"
              }, [_vm._v("Preview Message")]), _c('b-button', {
                attrs: {
                  "variant": "close-btn"
                },
                on: {
                  "click": function click($event) {
                    return _vm.$bvModal.hide('previewMessage');
                  }
                }
              }, [_c('i', {
                staticClass: "fas fa-times"
              })])], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "row popUp-details mb-2"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('label', {}, [_c('strong', [_vm._v("To : ")])]), _c('div', {
          staticClass: "preview-user"
        }, _vm._l(_vm.previewUsers, function (user, key) {
          return _c('span', {
            key: key
          }, [_vm._v(" " + _vm._s(user) + " ")]);
        }), 0)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('label', {
          attrs: {
            "for": "subject"
          }
        }, [_c('strong', [_vm._v("Subject : ")])]), _c('p', [_vm._v(" " + _vm._s(_vm.form.subject) + " ")])])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('label', {
          attrs: {
            "for": "subject"
          }
        }, [_c('strong', [_vm._v("Message : ")])]), _c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.form.message)
          }
        })])]), _c('div', {
          staticClass: "w-100"
        }, [_c('button', {
          staticClass: "btn add-filtre-btn",
          attrs: {
            "type": "submit"
          },
          on: {
            "click": function click($event) {
              return _vm.sendMessage(true);
            }
          }
        }, [_vm._v(" Send ")])])])], 1)])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xl-4"
        }, [_c('input-multi-select', {
          attrs: {
            "vmodel": _vm.form.userType,
            "labelName": "Message For",
            "inputClass": "mb-3",
            "options": _vm.userTypes.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.userTypes.find(function (x) {
                return x.value == opt;
              }).text;
            },
            "rules": {
              required: true
            },
            "placeholder": "Select User Type"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "userType", $event);
            },
            "onInput": function onInput($event) {
              return _vm.getUsersList();
            }
          }
        })], 1), _c('div', {
          staticClass: "col-xl-4"
        }), _c('div', {
          staticClass: "col-xl-4 d-flex justify-content-end"
        }, [_c('div', {
          staticClass: "more-info"
        }, [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('b-button', {
          staticClass: "add-msg",
          attrs: {
            "modal-centered": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.show('more-info-advance');
            }
          }
        }, [_vm._v("Add Advance filter")]) : _vm._e(), _vm.type !== 'draft' && _vm.form.mid == '' || _vm.type == 'draft' && _vm.form.mid ? _c('b-modal', {
          attrs: {
            "hide-footer": true,
            "id": "more-info-advance",
            "centered": "",
            "size": "lg",
            "no-close-on-backdrop": ""
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "popUp-header text-center w-100",
                staticStyle: {
                  "border-bottom": "0px !important"
                }
              }, [_c('h4', {
                staticClass: "header-popup"
              }, [_vm._v("Advance Filter")]), _c('b-button', {
                attrs: {
                  "variant": "close-btn"
                },
                on: {
                  "click": function click($event) {
                    return _vm.$bvModal.hide('more-info-advance');
                  }
                }
              }, [_c('i', {
                staticClass: "fas fa-times"
              })])], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm.form.userType == 'M' || _vm.form.userType == 'A' || _vm.form.userType == 'G' ? _c('div', {
          staticClass: "row popUp-details mt-1"
        }, [_c('div', {
          staticClass: "col-xl-4"
        }, [_c('div', {
          staticClass: "filter-label"
        }, [_vm._v("Rep Tree")]), _c('div', {
          staticClass: "mt-2"
        }, [_c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_rep_list,
            expression: "include_rep_list"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "1",
            "checked": "",
            "type": "radio",
            "name": "include_rep_list"
          },
          domProps: {
            "checked": _vm._q(_vm.include_rep_list, "1")
          },
          on: {
            "click": function click($event) {
              return _vm.refreshListing(1);
            },
            "change": function change($event) {
              _vm.include_rep_list = "1";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Include ")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_rep_list,
            expression: "include_rep_list"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "0",
            "type": "radio",
            "name": "include_rep_list"
          },
          domProps: {
            "checked": _vm._q(_vm.include_rep_list, "0")
          },
          on: {
            "click": function click($event) {
              return _vm.refreshListing(0);
            },
            "change": function change($event) {
              _vm.include_rep_list = "0";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Exclude ")])])]), _c('div', [_c('custom-button', {
          staticClass: "btn univ-btn",
          attrs: {
            "title": _vm.sendRepMessageSelectedReps.length ? "".concat(_vm.sendRepMessageSelectedReps.length, " Rep Selected") : "Select Rep",
            "haveText": ""
          },
          on: {
            "onClicked": _vm.openAddRepModal
          }
        })], 1)]), _c('div', {
          staticClass: "col-xl-8"
        }, [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "btn btn-danger",
          on: {
            "click": function click($event) {
              return _vm.clearFilter();
            }
          }
        }, [_vm._v("Clear")])], 1)])]) : _vm._e(), _c('div', {
          staticClass: "row popUp-details mt-4"
        }, [_c('div', {
          staticClass: "col-xl-4"
        }, [_c('div', [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('div', {}, [_c('label', {
          staticClass: "include_label"
        }, [_vm._v("State")]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_state,
            expression: "include_state"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "1",
            "checked": "",
            "type": "radio",
            "name": "include_state"
          },
          domProps: {
            "checked": _vm._q(_vm.include_state, "1")
          },
          on: {
            "change": function change($event) {
              _vm.include_state = "1";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Include ")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_state,
            expression: "include_state"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "0",
            "type": "radio",
            "name": "include_state"
          },
          domProps: {
            "checked": _vm._q(_vm.include_state, "0")
          },
          on: {
            "change": function change($event) {
              _vm.include_state = "0";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Exclude ")])])]) : _vm._e(), _c('div', {
          staticClass: "advance-filter"
        }, [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('input-multi-select-multiple', {
          attrs: {
            "vmodel": _vm.selectedStateList,
            "inputClass": "mb-3 w-100",
            "options": _vm.states.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.states.find(function (x) {
                return x.value == opt;
              }).text;
            },
            "activeData": _vm.states,
            "trackBy": "value",
            "placeholder": "Select State",
            "selectionLabel": "State",
            "labelName": "State"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.selectedStateList = $event;
            },
            "update:activeData": function updateActiveData($event) {
              _vm.states = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.states = $event;
            }
          }
        }) : _vm._e()], 1)])]), _c('div', {
          staticClass: "col-xl-4"
        }, [_c('div', [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('div', {}, [_c('label', {
          staticClass: "include_label"
        }, [_vm._v("Plan Category")]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_plan_category,
            expression: "include_plan_category"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "1",
            "checked": "",
            "type": "radio",
            "name": "include_plan_category"
          },
          domProps: {
            "checked": _vm._q(_vm.include_plan_category, "1")
          },
          on: {
            "click": function click($event) {
              return _vm.getPlanList(1);
            },
            "change": function change($event) {
              _vm.include_plan_category = "1";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Include ")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_plan_category,
            expression: "include_plan_category"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "0",
            "type": "radio",
            "name": "include_plan_category"
          },
          domProps: {
            "checked": _vm._q(_vm.include_plan_category, "0")
          },
          on: {
            "click": function click($event) {
              return _vm.getPlanList(0);
            },
            "change": function change($event) {
              _vm.include_plan_category = "0";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Exclude ")])])]) : _vm._e(), _c('div', {
          staticClass: "advance-filter"
        }, [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('input-multi-select-multiple', {
          attrs: {
            "vmodel": _vm.selectedPlanCategoryList,
            "inputClass": "mb-3 w-100",
            "options": _vm.planCategory.map(function (type) {
              return type;
            }),
            "labelName": "Plan Category",
            "activeData": _vm.planCategory,
            "trackBy": "value",
            "placeholder": "Select Plan Category",
            "selectionLabel": "Plan"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.selectedPlanCategoryList = $event;
            },
            "update:activeData": function updateActiveData($event) {
              _vm.planCategory = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.planCategory = $event;
            }
          }
        }) : _vm._e()], 1)])]), _c('div', {
          staticClass: "col-xl-4"
        }, [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('div', {
          staticClass: "col-12"
        }, [_c('div', [_c('label', {
          staticClass: "include_label"
        }, [_vm._v("Plans")]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_plan,
            expression: "include_plan"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "1",
            "checked": "",
            "type": "radio",
            "name": "include_plan"
          },
          domProps: {
            "checked": _vm._q(_vm.include_plan, "1")
          },
          on: {
            "change": function change($event) {
              _vm.include_plan = "1";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Include ")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_plan,
            expression: "include_plan"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "0",
            "type": "radio",
            "name": "include_plan"
          },
          domProps: {
            "checked": _vm._q(_vm.include_plan, "0")
          },
          on: {
            "change": function change($event) {
              _vm.include_plan = "0";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Exclude ")])])]), _c('div', {
          staticClass: "advance-filter"
        }, [_c('input-multi-select-multiple', {
          staticClass: "user_label",
          attrs: {
            "vmodel": _vm.selectedPlans,
            "labelName": "Plan",
            "placeholder": "Select Plan",
            "selectionLabel": "Plan",
            "activeData": _vm.plans,
            "options": _vm.plans.map(function (type) {
              return type.plan_id;
            }),
            "customLabel": function customLabel(opt) {
              var obj = _vm.plans.find(function (x) {
                return x.plan_id == opt;
              });

              if (obj) {
                return obj.plan_name;
              }
            },
            "optionsLimit": 100,
            "isLoading": _vm.isUserLoading
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.selectedPlans = $event;
            },
            "update:activeData": function updateActiveData($event) {
              _vm.plans = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.plans = $event;
            }
          }
        })], 1)]) : _vm._e()])]), _c('div', {
          staticClass: "row popUp-details mt-4"
        }, [_vm.form.userType == 'M' ? _c('div', {
          staticClass: "col-xl-4"
        }, [_c('input-multi-select', {
          attrs: {
            "vmodel": _vm.selectedGender,
            "labelName": "Gender",
            "inputClass": "mb-3",
            "options": _vm.genderList.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.genderList.find(function (x) {
                return x.value == opt;
              }).text;
            },
            "placeholder": "Select Gender"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.selectedGender = $event;
            }
          }
        })], 1) : _vm._e(), _vm.form.userType == 'A' ? _c('div', {
          staticClass: "col-xl-4"
        }, [_c('input-multi-select', {
          attrs: {
            "vmodel": _vm.selectedLevel,
            "labelName": "Level",
            "inputClass": "mb-3",
            "options": _vm.repLevel,
            "custom-label": function customLabel(opt) {
              return _vm.repLevel.find(function (x) {
                return x == opt;
              });
            },
            "placeholder": "Select level"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.selectedLevel = $event;
            }
          }
        })], 1) : _vm._e(), _vm.form.userType == 'G' ? _c('div', {
          staticClass: "col-xl-4"
        }, [_c('label', {
          staticClass: "include_label"
        }, [_vm._v("Select Group Type")]), _c('div', {
          staticClass: "advance-filter"
        }, [_c('input-multi-select-multiple', {
          staticClass: "user_label",
          attrs: {
            "vmodel": _vm.selectedGroupType,
            "labelName": "Group Type",
            "placeholder": "Select Group Type",
            "selectionLabel": "Group Type",
            "activeData": _vm.groupType,
            "options": _vm.groupType.map(function (type) {
              return type.value;
            }),
            "customLabel": function customLabel(opt) {
              var obj = _vm.groupType.find(function (x) {
                return x.value == opt;
              });

              if (obj) {
                return obj.text;
              }
            },
            "optionsLimit": 100,
            "isLoading": _vm.isUserLoading
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.selectedGroupType = $event;
            },
            "update:activeData": function updateActiveData($event) {
              _vm.groupType = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.groupType = $event;
            }
          }
        })], 1)]) : _vm._e(), _c('div', {
          staticClass: "col-xl-4"
        }, [_vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('input-text', {
          attrs: {
            "labelFor": "area_code",
            "labelName": "Area Code",
            "inputName": "area_code",
            "inputClass": "msg-input mb-3",
            "vmodel": _vm.form.area_code
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "area_code", $event);
            }
          }
        }) : _vm._e()], 1), _vm.form.userType == 'M' ? _c('div', {
          staticClass: "col-xl-4 form-group"
        }, [_c('div', {
          staticClass: "mb-2 mb-custom filter-label"
        }, [_vm._v(" Minimum Age ")]), _c('b-form-input', {
          staticClass: "range-custom-range",
          attrs: {
            "value": _vm.minAge,
            "state": null,
            "aria-describedby": "",
            "type": "range",
            "max": _vm.maxAge
          },
          on: {
            "input": _vm.updateMinAge
          }
        }), _c('div', [_vm._v(_vm._s(_vm.minAge))])], 1) : _vm._e(), _vm.form.userType == 'M' ? _c('div', {
          staticClass: "col-xl-4"
        }, [_c('div', {
          staticClass: "mb-2 filter-label"
        }, [_vm._v("Maximum Age")]), _c('b-form-input', {
          staticClass: "range-custom-range",
          attrs: {
            "value": _vm.maxAge,
            "state": null,
            "aria-describedby": "input-feedback-title input-help-title",
            "type": "range",
            "min": _vm.minAge
          },
          on: {
            "input": _vm.updateMaxAge
          }
        }), _c('div', [_vm._v(_vm._s(_vm.maxAge))])], 1) : _vm._e(), _vm.form.userType == 'G' ? _c('div', {
          staticClass: "col-xl-4"
        }, [_c('div', {
          staticClass: "mb-2 filter-label"
        }, [_vm._v(" Number of member greater than ")]), _c('b-form-input', {
          staticClass: "range-custom-range",
          attrs: {
            "value": _vm.memberGreaterThen,
            "state": null,
            "aria-describedby": "input-feedback-title input-help-title",
            "type": "range"
          },
          on: {
            "input": _vm.updateGroupMember
          }
        }), _c('div', [_vm._v(_vm._s(_vm.memberGreaterThen))])], 1) : _vm._e(), _vm.form.userType == 'A' ? _c('div', {
          staticClass: "col-xl-4"
        }, [_c('div', {
          staticClass: "mb-2 filter-label"
        }, [_vm._v(" Number of Active Client greater than ")]), _c('b-form-input', {
          staticClass: "range-custom-range",
          attrs: {
            "value": _vm.activeClientGreaterThen,
            "state": null,
            "aria-describedby": "input-feedback-title input-help-title",
            "type": "range"
          },
          on: {
            "input": _vm.updateActiveClient
          }
        }), _c('div', [_vm._v(_vm._s(_vm.activeClientGreaterThen))])], 1) : _vm._e()]), _c('div', {
          staticClass: "mt-3 d-flex justify-content-center"
        }, [_c('button', {
          staticClass: "btn w-25 add-filtre-btn",
          attrs: {
            "type": "submit"
          },
          on: {
            "click": _vm.filter
          }
        }, [_vm._v(" Save ")])])])]) : _vm._e()], 1)]), _c('div', {
          staticClass: "col-xl-10"
        })]), _vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? _c('div', {
          staticClass: "col-xl-4",
          staticStyle: {
            "margin-bottom": "0%",
            "margin-top": "2%"
          }
        }, [_vm.form.userType == 'A' ? _c('label', {
          staticClass: "include_label"
        }, [_vm._v(" Rep List"), _c('span', {
          staticClass: "input-required"
        }, [_vm._v("* ")])]) : _vm.form.userType == 'M' ? _c('label', {
          staticClass: "include_label"
        }, [_vm._v("Member List"), _c('span', {
          staticClass: "input-required"
        }, [_vm._v("* ")])]) : _c('label', {
          staticClass: "include_label"
        }, [_vm._v("Group List"), _c('span', {
          staticClass: "input-required"
        }, [_vm._v("* ")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_member,
            expression: "include_member"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "1",
            "checked": "",
            "type": "radio",
            "name": "include_member"
          },
          domProps: {
            "checked": _vm._q(_vm.include_member, "1")
          },
          on: {
            "change": function change($event) {
              _vm.include_member = "1";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Include ")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.include_member,
            expression: "include_member"
          }],
          staticClass: "form-check-input",
          attrs: {
            "value": "0",
            "type": "radio",
            "name": "include_member"
          },
          domProps: {
            "checked": _vm._q(_vm.include_member, "0")
          },
          on: {
            "change": function change($event) {
              _vm.include_member = "0";
            }
          }
        }), _c('label', {
          staticClass: "form-check-label"
        }, [_vm._v(" Exclude ")])])]) : _vm._e(), _vm.form.userType == 'M' || _vm.form.userType == 'G' || _vm.form.userType == 'A' ? [_c('input-multi-select-multiple', {
          staticClass: "user_label",
          attrs: {
            "vmodel": _vm.receiver,
            "labelName": "User",
            "activeData": _vm.users,
            "rules": {
              required: true
            },
            "options": _vm.users.map(function (type) {
              return type.id;
            }),
            "customLabel": function customLabel(opt) {
              var obj = _vm.users.find(function (x) {
                return x.id == opt;
              });

              if (obj) {
                return obj.name;
              }
            },
            "optionsLimit": 100,
            "isLoading": _vm.isUserLoading
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              _vm.receiver = $event;
            },
            "update:activeData": function updateActiveData($event) {
              _vm.users = $event;
            },
            "update:active-data": function updateActiveData($event) {
              _vm.users = $event;
            },
            "search": _vm.searchUser
          }
        })] : _vm._e(), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "subject",
            "labelName": "Subject",
            "inputName": "subject",
            "vmodel": _vm.form.subject,
            "formGroupClass": "form-group mb-3 mt-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "subject", $event);
            }
          }
        }), _c('b-form-group', [_c('label', {
          attrs: {
            "id": "Files",
            "for": "files"
          }
        }, [_vm._v(" Attachments")]), _c('b-form-file', {
          attrs: {
            "browse-text": "Choose File",
            "placeholder": "Attachments",
            "multiple": "multiple",
            "accept": "png,jpg,xlx,xlsx,pdf,txt,csv,jpeg"
          },
          model: {
            value: _vm.files,
            callback: function callback($$v) {
              _vm.files = $$v;
            },
            expression: "files"
          }
        })], 1), _vm.attachments.length > 0 ? _c('div', {
          staticStyle: {
            "margin-top": "2%"
          }
        }, [_c('h6', [_vm._v("Attachments:")]), _c('ul', {
          staticStyle: {
            "list-style": "none"
          }
        }, _vm._l(_vm.attachments, function (file) {
          return _c('li', {
            key: file.attachment_id
          }, [_c('a', {
            attrs: {
              "href": file.file_url,
              "target": "__blank",
              "download": ""
            }
          }, [_c('i', {
            staticClass: "fa fa-file"
          }), _vm._v(" " + _vm._s(file.title) + " ")]), _c('span', {
            staticStyle: {
              "margin-left": "20%",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.removeAttachments(file.attachment_id);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-trash",
            staticStyle: {
              "color": "red"
            }
          })])]);
        }), 0)]) : _vm._e(), _c('div', {
          staticClass: "form-group mb-4 mt-3"
        }, [_c('validation-provider', {
          attrs: {
            "name": "Message",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('label', {
                attrs: {
                  "for": ""
                }
              }, [_vm._v("Message "), _c('span', {
                staticClass: "required-indc"
              }, [_vm._v("*")])]), _c('quill-editor', {
                ref: "myQuillEditor",
                attrs: {
                  "options": _vm.editorOption
                },
                model: {
                  value: _vm.form.message,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "message", $$v);
                  },
                  expression: "form.message"
                }
              }), _c('div', {
                staticClass: "invalid-block invalid-feedback"
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _vm.type === 'draft' ? [_c('div', {
          staticClass: "row justify-content-md-end justify-content-center"
        }, [_c('b-button', {
          staticClass: "univ-btn save-to-draft",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          },
          on: {
            "click": function click($event) {
              _vm.saveToDraft = true;
            }
          }
        }, [_vm.isSend && _vm.saveToDraft ? [_vm._v(" Saving ... ")] : [_vm._v(" Save ")]], 2), _c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          },
          on: {
            "click": function click($event) {
              _vm.publishDraft = true;
            }
          }
        }, [_vm.isSend && _vm.publishDraft ? [_vm._v(" Publishing ... ")] : [_vm._v(" Publish ")]], 2)], 1)] : [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-lg-4 col-md-4 col-12 col-xl-4 col-xxl-4 d-flex justify-content-center"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          },
          on: {
            "click": function click($event) {
              _vm.saveToDraft = true;
            }
          }
        }, [_vm.isSend && _vm.saveToDraft ? [_vm._v(" Saving ... ")] : [_vm._v(" Save to Draft ")]], 2)], 1), _c('div', {
          staticClass: "col-lg-4 col-md-4 col-12 col-xl-4 col-xxl-4 d-flex justify-content-center"
        }, [_c('b-button', {
          staticClass: "univ-btn mr-2",
          attrs: {
            "type": "submit",
            "disabled": _vm.isSend
          }
        }, [_vm.isSend ? [_vm._v(" Sending ... ")] : [_vm._v(" Send ")]], 2)], 1), _c('div', {
          staticClass: "col-lg-4 col-md-4 col-12 col-xl-4 col-xxl-4 d-flex justify-content-center"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          on: {
            "click": _vm.openPreviewMessageModal
          }
        }, [_vm._v(" Preview Message ")])], 1)])], _vm.index == 2 ? [_c('rep-tree-modal', {
          attrs: {
            "downlineList": _vm.downlineList,
            "messageCenterType": 'compose-message'
          }
        })] : _vm._e()], 2)];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }