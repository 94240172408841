<template>
  <div class="msg-member-list">
    <div class="msg-user-list" v-for="(item, index) in members" :key="index">
      <div class="img">
        <img :src="item.image ? item.image : dummyImage" alt="" />
      </div>
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>
<script>
import dummyImage from "@/assets/images/user.svg";
export default {
  name: "MessageMemberList",
  props: {
    members: {
      required: true,
    },
  },
  data: () => ({
    dummyImage,
  }),
};
</script>
<style scoped>
.msg-member-list{
  display:flex
}
.msg-user-list img {
    height: 58px;
    width: 58px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100px;
    opacity: 0.9;
    overflow: hidden;
    background-color: #BEE8FC;
}

.msg-user-list {
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: #707070;
    max-width: 100px;
}
@media (max-width: 1400px) {
  .msg-user-list img {
    height: 40px;
    width: 40px;
  }
}
</style>