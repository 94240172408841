var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "msg-member-list"
  }, _vm._l(_vm.members, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "msg-user-list"
    }, [_c('div', {
      staticClass: "img"
    }, [_c('img', {
      attrs: {
        "src": item.image ? item.image : _vm.dummyImage,
        "alt": ""
      }
    })]), _c('span', [_vm._v(_vm._s(item.name))])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }