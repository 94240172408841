<template>
  <div class="receiveMessage-wrap">
    <div class="row">
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
        <div>
          <search-filter @searchMessageList="searchMessageList" :index="index"/>
        </div>
        <div class="receiveMessage-card mt-3">
          <div class="receiveMessage_headTitle">
            <h2>Recieved Messages</h2>
            <!-- <div class="msg-filter">
              <select>
                <option>All</option>
                <option>Read</option>
                <option>Unread</option>
                <option>Archive</option>
              </select>
            </div> -->
          </div>
          <message-list
            :rows.sync="rows"
            @onClickedItem="getMessage"
            type="receive"
          />
          <div class="table-pagination">
            <pagination :rows.sync="rows" />
          </div>
        </div>
      </div>

      <message-content
        :message="showMessage"
        type="receive"
        @onSuccess="getAll"
      />
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import MessageList from "../partials/MessageList";
import MessageContent from "../partials/MessageContent.vue";
import searchFilter from "../partials/search-filter.vue";
export default {
  name: "ReceiveMessage",
  props: ['index'],
  components: {
    MessageList,
    MessageContent,
    searchFilter
  },
  data: () => ({
    rows: [],
    canCompose: false,
    showMessage: null,
    searchQuery: null
  }),
  methods: {
    getMessage(item) {
      this.showMessage = item;
    },
    searchMessageList (data) {
      let app = this
      app.searchQuery = data
      app.getAll(app.searchQuery)
    },
    getAll(searchQuery) {
      if (this.index == 1) {
        let url = "v2/get-received-message-list";
        let loader;
        loader = this.$loading.show();
        if (searchQuery) {
          const encodedQuery = encodeURIComponent(searchQuery);
          url += `?search=${encodedQuery}`;
        }
        /*  if (this.$route.params.selectedReceivedMessage) {
          loader = this.$loading.show();
        }*/
        Api.get(url)
            .then((res) => {
              this.rows = res.data;
              if (this.$route.params.selectedReceivedMessage) {
                this.showMessage = this.$route.params.selectedReceivedMessage;
              } else {
                this.showMessage = this.rows.records[0];
              }
            })
            .catch(() => {
              this.rows = [];
              this.showMessage = null;
            })
            .finally(() => {
              loader.hide();
              /*if (this.$route.params.selectedReceivedMessage) {
            loader.hide();
          }*/
            });
      }
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    index: function (newVal, oldVal) {
      if (newVal != oldVal && newVal == 1) {
        this.getAll();
      }
    }
  }
};
</script>
