var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper msg-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "msg-more-option"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link",
      "toggle-class": "text-decoration-none",
      "right": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fas fa-ellipsis-v"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 3;
      }
    }
  }, [_vm._v("Draft")])], 1)], 1), _c('b-tabs', {
    attrs: {
      "card": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Sent"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 0;
      }
    }
  }, [_c('b-card-text', [_vm.tabIndex == 0 ? _c('send-message', {
    attrs: {
      "index": _vm.tabIndex
    }
  }) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Received",
      "id": "link-to-received"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 1;
      }
    }
  }, [_c('b-card-text', [_vm.tabIndex == 1 ? _c('receive-message', {
    attrs: {
      "index": _vm.tabIndex
    }
  }) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Compose",
      "id": "link-to-compose"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 2;
      }
    }
  }, [_c('b-card-text', [_vm.tabIndex == 2 ? _c('send-message-compose', {
    attrs: {
      "index": _vm.tabIndex,
      "type": "compose"
    },
    on: {
      "renderTabInfo": _vm.renderTabIndex
    }
  }) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Draft",
      "id": "link-to-draft",
      "title-link-class": "amc-tab-hide"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 3;
      }
    }
  }, [_c('b-card-text', [_vm.tabIndex == 3 ? _c('DraftMessage', {
    attrs: {
      "index": _vm.tabIndex
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('add-message')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title",
    staticStyle: {
      "padding-left": "1.2rem"
    }
  }, [_c('h1', [_vm._v(" Admin Message Center ")])]);
}]

export { render, staticRenderFns }