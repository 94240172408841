var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "d-none msg-node msg-node-".concat(_vm.upline.agent_id),
    attrs: {
      "id": "send-message-node-inner-".concat(_vm.upline.agent_id)
    }
  }, _vm._l(_vm.downline, function (downlineList) {
    return _c('div', {
      key: downlineList.agent_id,
      class: "node-branch node-branch-".concat(downlineList.agent_id)
    }, [_c('div', {
      staticClass: "branch"
    }, [_c('div', {
      staticClass: "line-link",
      style: _vm.firstSubNode ? 'margin-left: 0.1rem' : ''
    }, [_c('div', {
      class: _vm.firstSubNode ? 'entry sub' : 'entry'
    }, [_c('img', {
      staticClass: "right-arrow-icon",
      attrs: {
        "src": require("@/assets/images/right-arrow-icon.svg")
      }
    }), _c('send-message-rep-tree-card', {
      attrs: {
        "rep": downlineList,
        "className": "spilt-down",
        "parentUplineId": _vm.upline.agent_id
      },
      on: {
        "onCheckAllDownline": _vm.onCheckAllDownlineHandler,
        "onRemoveDownlines": _vm.removeRelatedDownlines
      }
    }), downlineList.downline ? [_c('send-message-rep-tree-node', {
      attrs: {
        "downline": downlineList.downline,
        "upline": downlineList
      },
      on: {
        "onUpdateSelectedReps": _vm.newSelectedReps
      }
    })] : _vm._e()], 2)])])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }