<template>
  <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
    <div class="receiveMessage-detailcard" v-if="message">
      <div class="recieved-actions">
        <ul>
          <!-- <li>
            <a href="#" v-b-tooltip.hover title="Print"
              ><i class="fas fa-print"></i
            ></a>
          </li>
          <li>
            <a href="#" v-b-tooltip.hover title="Move To Archive"
              ><i class="fas fa-archive"></i
            ></a>
          </li> -->
          <li class="received-actions-list" v-if="sentUserLists.length > 0">
            <custom-button
              title="View All members"
              iconClass="fas fa-list-ul"
              @click.native="viewMembers()"
            />
          </li>
          <li v-if="type == 'archive'">
            <custom-button title="Unarchive" iconClass="fas fa-undo" />
          </li>
          <!-- <li v-if="type == 'receive'">
            <custom-button
              title="Print"
              iconClass="fas fa-print"
              onclick="window.print()"
            />
          </li>
          <li v-if="type == 'receive'">
            <custom-button
              title="Archive"
              iconClass="fas fa-archive"
            />
          </li> -->
          <li>
            <custom-button
              title="Delete Message"
              iconClass="far fa-trash-alt"
              @click.native="deleteMessage(message.mid)"
            />
          </li>
        </ul>
      </div>
      <div class="rcv-msgHead">
        <template v-if="type == 'sent'">
          <message-member-list :members.sync="firstFiveUsers" />
        </template>
        <!-- <div class="sndr-head">
          <div class="snder-icon">
            <img src="@/assets/images/user.svg" alt="" />
          </div>
          <div class="snder-detail">
            <h2 v-if="message.type == 'receive'">{{ message.from ? message.from : "--" }}</h2>
            <p>
              <i class="far fa-calendar-alt"></i>
              {{
                message.date_created
                  | date_format("MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY")
              }}
            </p>
          </div>
        </div> -->
        <div class="rcv-msgcontent">
          <div class="rcv-msg-sender" v-if="type == 'receive'">
            <div class="rcv-snder-icon">
              <img :src="image" alt="" class="msg-userIcon" />
              <div class="rcv-sender-details">
                <h6>{{ message.from ? message.from : "--" }}</h6>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{
                    message.date_created
                      | date_format("MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY")
                  }}
                </p>
              </div>
            </div>
          </div>
          <h3>Subject: {{ message.subject }}</h3>
          <hr />
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="message.message" />
            </div>
          </div>
          <div>
            <h6 v-if="message.attachments.length > 0">Attachments:</h6>
            <ul style="list-style: none">
              <li v-for="file in message.attachments" :key="file.id">
                <a :href="file.file_url" target="__blank" download>
                  <i class="fa fa-file"></i>
                  {{ file.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template v-if="type == 'sent'">
        <message-member-view :members="sentUserLists" />
      </template>
      <template v-if="type == 'draft'">
        <SendDraftMessageCompose
          :messageLog="messageLog"
          :index="index"
          type="draft"
          class="draft-edit-wrap"
          @onGetDownlineCheckList="downlineListHandler"
        />
      </template>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import Api from "@/resource/Api";
import dummyImage from "@/assets/images/user.svg";
import MessageMemberList from "./MessageMemberList.vue";
import MessageMemberView from "./MessageMemberView.vue";
import SendDraftMessageCompose from "./SendMessageDraftCompose.vue";
export default {
  name: "MessageContent",
  components: {
    MessageMemberList,
    MessageMemberView,
    SendDraftMessageCompose,
  },
  props: {
    message: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    messageLog: {
      type: Object,
    },
  },
  data: () => ({
    sentUserLists: [],
    firstFiveUsers: [],
    index: 3,
    dummyImage,
  }),

  computed: {
    image() {
      if (this.message.sender_details && this.message.sender_details.image) {
        return this.message.sender_details.image;
      }
      return dummyImage;
    },
  },
  methods: {
    downlineListHandler(downlineList) {
      this.$emit("onGetDownlineCheckList", downlineList);
    },
    viewMembers() {
      this.$bvModal.show("messageMemberView");
    },

    deleteMessage(mId) {
      let url, type;
      type = this.type;
      if (type == "sent" || type == "draft") {
        url = `v2/delete-message?mid=${mId}`;
      } else if (type == "receive") {
        url = `v2/delete-received-message?mid=${mId}`;
      }
      this.$confirm({
        title: "Delete Message",
        message: `Do you want to delete this message?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    getSentUserLists() {
      if (this.type == "receive") {
        return this.setMessageSeen();
      }
      let loader = this.$loading.show();
      const url = `v2/get-message-sent-users?mid=${this.message.mid}`;
      Api.get(url)
        .then((res) => {
          this.sentUserLists = res.data.data;
          this.firstFiveUsers = res.data.data.slice(0, 5);
        })
        .catch(() => {
          this.sentUserLists = [];
          this.firstFiveUsers = [];
        })
        .finally(() => {
            loader.hide();
        });
    },
    setMessageSeen() {
      Api.put(`v2/update-message-seen-status?mid=${this.message.mid}`);
    },
  },
  created() {
    if (this.type == "sent") {
      this.getSentUserLists();
    }
  },
  watch: {
    message: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.getSentUserLists();
      }
    },
    messageLog: function (newVal, oldVal) {
      this.messageLog = newVal
    },
  },
};
</script>
