var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-8 col-xl-8 col-lg-8 col-md-8"
  }, [_vm.message ? _c('div', {
    staticClass: "receiveMessage-detailcard"
  }, [_c('div', {
    staticClass: "recieved-actions"
  }, [_c('ul', [_vm.sentUserLists.length > 0 ? _c('li', {
    staticClass: "received-actions-list"
  }, [_c('custom-button', {
    attrs: {
      "title": "View All members",
      "iconClass": "fas fa-list-ul"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.viewMembers();
      }
    }
  })], 1) : _vm._e(), _vm.type == 'archive' ? _c('li', [_c('custom-button', {
    attrs: {
      "title": "Unarchive",
      "iconClass": "fas fa-undo"
    }
  })], 1) : _vm._e(), _c('li', [_c('custom-button', {
    attrs: {
      "title": "Delete Message",
      "iconClass": "far fa-trash-alt"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.deleteMessage(_vm.message.mid);
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "rcv-msgHead"
  }, [_vm.type == 'sent' ? [_c('message-member-list', {
    attrs: {
      "members": _vm.firstFiveUsers
    },
    on: {
      "update:members": function updateMembers($event) {
        _vm.firstFiveUsers = $event;
      }
    }
  })] : _vm._e(), _c('div', {
    staticClass: "rcv-msgcontent"
  }, [_vm.type == 'receive' ? _c('div', {
    staticClass: "rcv-msg-sender"
  }, [_c('div', {
    staticClass: "rcv-snder-icon"
  }, [_c('img', {
    staticClass: "msg-userIcon",
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "rcv-sender-details"
  }, [_c('h6', [_vm._v(_vm._s(_vm.message.from ? _vm.message.from : "--"))]), _c('p', [_c('i', {
    staticClass: "far fa-calendar-alt"
  }), _vm._v(" " + _vm._s(_vm._f("date_format")(_vm.message.date_created, "MM/DD/YYYY HH:mm:ss", "MM/DD/YYYY")) + " ")])])])]) : _vm._e(), _c('h3', [_vm._v("Subject: " + _vm._s(_vm.message.subject))]), _c('hr'), _c('div', {
    staticClass: "ql-snow"
  }, [_c('div', {
    staticClass: "ql-editor"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.message.message)
    }
  })])]), _c('div', [_vm.message.attachments.length > 0 ? _c('h6', [_vm._v("Attachments:")]) : _vm._e(), _c('ul', {
    staticStyle: {
      "list-style": "none"
    }
  }, _vm._l(_vm.message.attachments, function (file) {
    return _c('li', {
      key: file.id
    }, [_c('a', {
      attrs: {
        "href": file.file_url,
        "target": "__blank",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-file"
    }), _vm._v(" " + _vm._s(file.title) + " ")])]);
  }), 0)])])], 2), _vm.type == 'sent' ? [_c('message-member-view', {
    attrs: {
      "members": _vm.sentUserLists
    }
  })] : _vm._e(), _vm.type == 'draft' ? [_c('SendDraftMessageCompose', {
    staticClass: "draft-edit-wrap",
    attrs: {
      "messageLog": _vm.messageLog,
      "index": _vm.index,
      "type": "draft"
    },
    on: {
      "onGetDownlineCheckList": _vm.downlineListHandler
    }
  })] : _vm._e()], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }