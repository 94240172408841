<template>
  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
    <div class="sendMessage-Formcard">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
        <form
          @submit.stop.prevent="handleSubmit(sendMessage)"
          ref="sendMessageContainer"
        >
          <div class="row">
            <!-- pop up for preview message -->
              <div class="col-xl-4 d-flex justify-content-end">
                <div class="message-display-section">
                  <b-modal
                    v-bind:hide-footer="true"
                    id="previewMessage"
                    ref="previewMessage"
                    centered
                    size="lg"
                  >
                    <template #modal-header>
                      <div
                        class="popUp-header"
                        style="border-bottom: 0px !important"
                      >
                        <h4 class="header-popup">Preview Message</h4>
                        <b-button
                          variant="close-btn"
                          @click="$bvModal.hide('previewMessage')"
                        >
                          <i class="fas fa-times"></i>
                        </b-button>
                      </div>
                    </template>
                      <div class="row popUp-details mb-2">
                        <div class="col-md-12">
                          <label class=""><strong>To : </strong></label>
                          <div class="preview-user">
                            <span v-for="(user, key) in previewUsers" :key="key">
                              {{ user }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="subject"> <strong>Subject : </strong></label>
                          <p>
                            {{ form.subject }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <label for="subject"><strong>Message : </strong></label>
                          <div v-html="form.message">

                          </div>
                        </div>
                      </div>

                      <div class="w-100">
                        <button
                          type="submit"
                          @click="sendMessage(true)"
                          class="btn add-filtre-btn"
                        >
                          Send
                        </button>
                      </div>
                  </b-modal>
                </div>
              </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-4">
              <input-multi-select
                :vmodel.sync="form.userType"
                labelName="Message For"
                inputClass="mb-3"
                :options="userTypes.map((type) => type.value)"
                :custom-label="
                  (opt) => userTypes.find((x) => x.value == opt).text
                "
                @onInput="getUsersList()"
                :rules="{ required: true }"
                placeholder="Select User Type"
              />
            </div>
            <div class="col-xl-4"></div>
            <!-- pop up for Advance filtre -->
            <div class="col-xl-4 d-flex justify-content-end">
              <div class="more-info">
                <!-- <div class="more-info-content"> v-b-modal.more-info -->
                  <b-button @click="$bvModal.show('draft-advance-filter')"  modal-centered class="add-msg" v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'"
                    >Add Advance filter</b-button
                  >
                <!-- </div> -->

                <!--  Advance filter pop up model -->
                <b-modal
                  v-bind:hide-footer="true"
                  id="draft-advance-filter"
                  centered
                  size="lg"
                  no-close-on-backdrop
                  v-if="(type !== 'draft' && form.mid == '' ) || (type == 'draft' && form.mid )"
                >
                  <template #modal-header >
                    <div
                      class="popUp-header text-center w-100"
                      style="border-bottom: 0px !important"
                    >
                      <h4 class="header-popup">Advance Filter</h4>
                      <b-button
                        variant="close-btn"
                        @click="$bvModal.hide('draft-advance-filter')"
                      >
                        <i class="fas fa-times"></i>
                      </b-button>
                    </div>
                  </template>
                  <div>
                   <div class="row popUp-details mt-1" v-if="(form.userType == 'M' || form.userType == 'A' || form.userType == 'G')">
                     <div class="col-xl-4">
                       <div class="filter-label"> Rep Tree</div>
                         <div class="mt-2">
                          <div class="form-check form-check-inline">
                            <input value="1" @click="refreshListing(1)" v-model="include_rep_list" checked class="form-check-input" type="radio" name="include_rep_list" >
                            <label class="form-check-label">
                              Include
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input value="0" @click="refreshListing(0)" v-model="include_rep_list" class="form-check-input" type="radio" name="include_rep_list">
                            <label class="form-check-label">
                              Exclude
                            </label>
                          </div>
                        </div>
                        <div>
                          <custom-button
                              :title="
                               sendRepMessageSelectedReps.length
                               ? `${sendRepMessageSelectedReps.length} Rep Selected`
                               : `Select Rep`"
                              haveText
                              @onClicked="openAddRepModal"
                              class="btn univ-btn"
                          />
                      </div>
                     </div>
                     <div class="col-xl-8">
                       <div class="d-flex justify-content-end">
                         <b-button @click="clearFilter()" class="btn btn-danger">Clear</b-button>
                       </div>
                     </div>
                   </div>
                   <div class="row popUp-details mt-4">
                    <div class="col-xl-4">
                      <div>
                        <div class=""  v-if="(form.userType == 'M' || form.userType == 'G' || form.userType == 'A')">
                          <label class="include_label"  >State</label>
                          <div class="form-check form-check-inline">
                            <input value="1"  v-model="include_state" checked class="form-check-input" type="radio" name="include_state" >
                            <label class="form-check-label">
                              Include
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input value="0"  v-model="include_state" class="form-check-input" type="radio" name="include_state">
                            <label class="form-check-label">
                              Exclude
                            </label>
                          </div>
                        </div>
                        <div class="advance-filter">
                          <input-multi-select-multiple
                              v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'"
                              :vmodel.sync="selectedStateList"
                              inputClass="mb-3 w-100"
                              :options="states.map((type) => type.value)"
                              :custom-label="(opt) => states.find((x) => x.value == opt).text"
                              :activeData.sync="states"
                              trackBy="value"
                              placeholder="Select State"
                              selectionLabel="State"
                              labelName="State"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div>
                        <div class=""  v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'">
                          <label class="include_label"  >Plan Category</label>
                          <div class="form-check form-check-inline">
                            <input value="1" @click="getPlanList(1)"  v-model="include_plan_category" checked class="form-check-input" type="radio" name="include_plan_category" >
                            <label class="form-check-label">
                              Include
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input value="0" @click="getPlanList(0)"  v-model="include_plan_category" class="form-check-input" type="radio" name="include_plan_category">
                            <label class="form-check-label">
                              Exclude
                            </label>
                          </div>
                        </div>
                        <div class="advance-filter">
                          <input-multi-select-multiple
                              v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'"
                              :vmodel.sync="selectedPlanCategoryList"
                              inputClass="mb-3 w-100"
                              :options="planCategory.map((type) => type)"
                              labelName="Plan Category"
                              :activeData.sync="planCategory"
                              trackBy="value"
                              placeholder="Select Plan Category"
                              selectionLabel="Plan"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="col-12" v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'" >
                        <!-- plan include exclude button starts -->
                        <div>
                          <label class="include_label">Plans</label>
                          <div class="form-check form-check-inline">
                            <input value="1" v-model="include_plan"  checked class="form-check-input" type="radio" name="include_plan" >
                            <label class="form-check-label">
                              Include
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input value="0"  v-model="include_plan" class="form-check-input" type="radio" name="include_plan">
                            <label class="form-check-label">
                              Exclude
                            </label>
                          </div>
                        </div>
                        <!-- plan include exclude button ends -->
                        <div class="advance-filter">
                          <input-multi-select-multiple
                              :vmodel.sync="selectedPlans"
                              labelName="Plan"
                              class="user_label"
                              placeholder="Select Plan"
                              selectionLabel="Plan"
                              :activeData.sync="plans"
                              :options="plans.map((type) => type.plan_id)"
                              :customLabel="
                              (opt) => {
                                let obj = plans.find((x) => x.plan_id == opt);
                                if (obj) {
                               return obj.plan_name;
                                }
                                }
                               "
                              :optionsLimit="100"
                              :isLoading="isUserLoading"
                          />
                        </div>
                      </div>
                    </div>
                   </div>
                   <div class="row popUp-details mt-4">
                     <div class="col-xl-4" v-if="form.userType == 'M'">
                       <input-multi-select
                           :vmodel.sync="selectedGender"
                           labelName="Gender"
                           inputClass="mb-3"
                           :options="genderList.map((type) => type.value)"
                           :custom-label="
                          (opt) => genderList.find((x) => x.value == opt).text"
                           placeholder="Select Gender"
                       />
                     </div>
                     <div class="col-xl-4" v-if="form.userType == 'A'">
                       <input-multi-select
                           :vmodel.sync="selectedLevel"
                           labelName="Level"
                           inputClass="mb-3"
                           :options="repLevel"
                           :custom-label="
                           (opt) => repLevel.find((x) => x == opt)"
                           placeholder="Select level"
                       />
                     </div>
                     <div class="col-xl-4"  v-if="form.userType == 'G'">
                       <label class="include_label">Select Group Type</label>
                       <div class="advance-filter">
                         <input-multi-select-multiple
                             :vmodel.sync="selectedGroupType"
                             labelName="Group Type"
                             class="user_label"
                             placeholder="Select Group Type"
                             selectionLabel="Group Type"
                             :activeData.sync="groupType"
                             :options="groupType.map((type) => type.value)"
                             :customLabel="
                             (opt) => {
                              let obj = groupType.find((x) => x.value == opt);
                              if (obj) {
                             return obj.text;
                              }
                              }
                             "
                             :optionsLimit="100"
                             :isLoading="isUserLoading"
                         />
                       </div>
                     </div>
                     <div class="col-xl-4">
                       <input-text
                           v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'"
                           labelFor="area_code"
                           labelName="Area Code"
                           inputName="area_code"
                           inputClass="msg-input mb-3"
                           :vmodel.sync="form.area_code"

                       />
                     </div>
                     <div class="col-xl-4 form-group" v-if="form.userType == 'M'">
                       <div class="mb-2 mb-custom filter-label">Minimum Age</div>
                       <b-form-input
                           class="range-custom-range"
                           :value="minAge"
                           :state="null"
                           @input="updateMinAge"
                           aria-describedby=""
                           type="range"
                           :max="maxAge"
                       >
                       </b-form-input>
                       <div>{{ minAge }}</div>
                     </div>
                     <div class="col-xl-4" v-if="form.userType == 'M'">
                       <div class="mb-2 filter-label">Maximum Age {{maxAge}}</div>
                       <b-form-input
                           :value="maxAge"
                           class="range-custom-range"
                           @input="updateMaxAge"
                           :state="null"
                           aria-describedby="input-feedback-title input-help-title"
                           type="range"
                           :min="minAge"
                       >
                       </b-form-input>
                       <div>{{ maxAge }} </div>
                     </div>
                     <div class="col-xl-4" v-if="form.userType == 'G'">
                       <div class="mb-2 filter-label">Number of member greater than</div>
                       <b-form-input
                           :value="memberGreaterThen"
                           class="range-custom-range"
                           @input="updateGroupMember"
                           :state="null"
                           aria-describedby="input-feedback-title input-help-title"
                           type="range"
                       >
                       </b-form-input>
                       <div>{{ memberGreaterThen }} </div>
                     </div>
                     <div class="col-xl-4" v-if="form.userType == 'A'">
                       <div class="mb-2 filter-label">Number of Active Client greater than</div>
                       <b-form-input
                           :value="activeClientGreaterThen"
                           class="range-custom-range"
                           @input="updateActiveClient"
                           :state="null"
                           aria-describedby="input-feedback-title input-help-title"
                           type="range"
                       >
                       </b-form-input>
                       <div>{{ activeClientGreaterThen }} </div>
                     </div>
                    </div>
                    <div class="mt-3 d-flex justify-content-center">
                      <button
                        type="submit"
                        @click="filter"
                        class=" btn w-25 add-filtre-btn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="col-xl-10">
                                  <!-- state include exclude button starts
<div class="" style="margin-bottom: -2%;"   v-if="form.userType == 'M' || form.userType == 'G'">
  <label class="include_label"  >State <span class="input-required">* </span></label>
<div class="form-check form-check-inline">
  <input value="1" v-on:change="getUsersList" v-model="include_state" checked class="form-check-input" type="radio" name="include_state" >
  <label class="form-check-label">
    Include
  </label>
</div>
  <div class="form-check form-check-inline">
      <input value="0" v-on:change="getUsersList" v-model="include_state" class="form-check-input" type="radio" name="include_state">
      <label class="form-check-label">
        Exclude
      </label>
    </div>
</div>-->
           <!-- state_field include exclude button ends
              <input-multi-select
                v-if="form.userType == 'M' || form.userType == 'G'"
                :vmodel.sync="form.state"
                labelName=""
                inputClass="mb-3"
                :options="states.map((type) => type.value)"
                :custom-label="(opt) => states.find((x) => x.value == opt).text"
                @onInput="getUsersList()"
                placeholder="Select State"
              />-->
            </div>

            <!--<div class="col-xl-4">
              <input-text
                v-if="form.userType == 'M' || form.userType == 'G'"
                labelFor="area_code"
                labelName="Area Code"
                inputName="area_code"
                inputClass="msg-input mb-3"
                :vmodel.sync="form.area_code"
                @keyup.native="getUsersWithAreaCode()"
              />
            </div> -->
          </div>
                    <!-- plan include exclude button starts -->
<!--<div class="col-xl-4" style="margin-bottom: -2%;"   v-if="form.userType == 'M' || form.userType == 'G'">
  <label class="include_label"  >Plans <span class="input-required">* </span></label>
<div class="form-check form-check-inline">
  <input value="1" v-model="include_plan" v-on:change="getUsersList" checked class="form-check-input" type="radio" name="include_plan" >
  <label class="form-check-label">
    Include
  </label>
</div>
  <div class="form-check form-check-inline">
      <input value="0" v-on:change="getUsersList" v-model="include_plan" class="form-check-input" type="radio" name="include_plan">
      <label class="form-check-label">
        Exclude
      </label>
    </div>
</div> -->
           <!-- plan include exclude button ends -->
          <!--<multi-selectable-input
            v-if="form.userType == 'M' || form.userType == 'G'"
            :vmodel.sync="selectedPlans"
            labelName=""
            :options="plans"
            displayLabel="plan_name"
            formGroupClass="col-xxl-4 mb-3"
            placeholder="Select Plans"
            :optionsLimit="planOptionsLimit"
            :activeData.sync="plans"
            @onInput="getUsersList()"
            @search="searchPlan"
            trackBy="plan_id"
            :isLoading="isPlanLoading"
          />-->

        <!-- member include exclude button starts -->
<!--        <div class="col-xl-4" style="margin-bottom: 0%;" v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'">
          <label class="include_label">{{"Rep List"}} <span class="input-required">* </span></label>
        <div class="form-check form-check-inline">
          <input value="1" @click="refreshListing(1)" v-model="include_rep_list" checked class="form-check-input" type="radio" name="include_rep_list" >
          <label class="form-check-label">
            Include
          </label>
        </div>
          <div class="form-check form-check-inline">
              <input value="0" @click="refreshListing(0)" v-model="include_rep_list" class="form-check-input" type="radio" name="include_rep_list">
              <label class="form-check-label">
                Exclude
              </label>
            </div>
        </div>-->
        <!-- member include exclude button ends -->

<!--          <template v-if="form.userType">
            <custom-button
              :title="
                sendRepMessageSelectedReps.length
                  ? `${sendRepMessageSelectedReps.length} Rep Selected`
                  : `Select Rep`
              "
              haveText
              @onClicked="openAddRepModal"
              class="btn univ-btn"
            />
          </template>-->

        <div class="col-xl-4" style="margin-bottom: 0%; margin-top: 2%;" v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'">
          <label class="include_label" v-if='form.userType == "A"'> Rep List<span class="input-required">* </span></label>
          <label class="include_label" v-else-if='form.userType == "M"'>Member List<span class="input-required">* </span></label>
          <label class="include_label" v-else>Group List<span class="input-required">* </span></label>
            <div class="form-check form-check-inline">
              <input value="1" v-model="include_member" checked class="form-check-input" type="radio" name="include_member" >
              <label class="form-check-label">
                Include
              </label>
            </div>
              <div class="form-check form-check-inline">
                  <input value="0" v-model="include_member" class="form-check-input" type="radio" name="include_member">
                  <label class="form-check-label">
                    Exclude
                  </label>
                </div>
          </div>
          <template v-if="form.userType == 'M' || form.userType == 'G' || form.userType == 'A'">
            <input-multi-select-multiple
              :vmodel.sync="receiver"
              labelName="User"
              class="user_label"
              :activeData.sync="users"
              :rules="{ required: true }"
              :options="users.map((type) => type.id)"
              :customLabel="
                (opt) => {
                  let obj = users.find((x) => x.id == opt);
                  if (obj) {
                    return obj.name;
                  }
                }
              "
              :optionsLimit="100"
              @search="searchUser"
              :isLoading="isUserLoading"
            />
          </template>

          <input-text
            :rules="{ required: true }"
            labelFor="subject"
            labelName="Subject"
            inputName="subject"
            :vmodel.sync="form.subject"
            formGroupClass="form-group mb-3 mt-3"
          />
          <!-- <input-file
          :rules="{ required: false, mimes: mimeTypes }"
          labelFor="attachments"
          labelName="Attachments"
          inputName="attachments"
          :vmodel.sync="files"
          :acceptMimeTypes="mimeTypes"
          formGroupClass="form-group mb-4"
          multiple
        /> -->
          <b-form-group>
            <label id="Files" for="files"> Attachments</label>
            <b-form-file
              browse-text="Choose File"
              v-model="files"
              placeholder="Attachments"
              multiple="multiple"
              accept="png,jpg,xlx,xlsx,pdf,txt,csv,jpeg"
            >
            </b-form-file>
          </b-form-group>

          <div v-if="attachments.length > 0" style="margin-top: 2%">
            <h6>Attachments:</h6>
            <ul style="list-style: none">
              <li v-for="file in attachments" :key="file.attachment_id">
                <a :href="file.file_url" target="__blank" download>
                  <i class="fa fa-file"></i>
                  {{ file.title }}
                </a>
                <span
                  @click="removeAttachments(file.attachment_id)"
                  style="margin-left: 20%; cursor: pointer"
                >
                  <i class="fas fa-trash" style="color: red"></i>
                </span>
              </li>
            </ul>
          </div>

          <div class="form-group mb-4 mt-3">
            <validation-provider
              name="Message"
              rules="required"
              v-slot="{ errors }"
            >
              <label for="">Message <span class="required-indc">*</span></label>
              <quill-editor
                ref="myQuillEditor"
                v-model="form.message"
                :options="editorOption"
              />
              <div class="invalid-block invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <template v-if="type === 'draft'">
            <div class="row justify-content-md-end justify-content-center ">
              <b-button
                @click="saveToDraft = true"
                class="univ-btn save-to-draft"
                type="submit"
                :disabled="isSend"
              >
                <template v-if="isSend && saveToDraft"> Saving ... </template>
                <template v-else> Save </template>
              </b-button>

              <b-button
                @click="publishDraft = true"
                class="univ-btn"
                type="submit"
                :disabled="isSend"
              >
                <template v-if="isSend && publishDraft">
                  Publishing ...
                </template>
                <template v-else> Publish </template>
              </b-button>
            </div>
          </template>

          <template v-else>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12 col-xl-4 col-xxl-4 d-flex justify-content-center">
                <b-button
                    @click="saveToDraft = true"
                    class="univ-btn"
                    type="submit"
                    :disabled="isSend"
                >
                  <template v-if="isSend && saveToDraft"> Saving ... </template>
                  <template v-else> Save to Draft </template>
                </b-button>
              </div>
              <div class="col-lg-4 col-md-4 col-12 col-xl-4 col-xxl-4 d-flex justify-content-center">
                <b-button class="univ-btn mr-2" type="submit" :disabled="isSend">
                  <template v-if="isSend"> Sending ... </template>
                  <template v-else> Send </template>
                </b-button>
              </div>
              <div class="col-lg-4 col-md-4 col-12 col-xl-4 col-xxl-4 d-flex justify-content-center">
                <b-button class="univ-btn" @click="openPreviewMessageModal" :disabled="invalid">
                  Preview Message
                </b-button>
              </div>
            </div>
          </template>
          <template>
            <rep-tree-modal :downlineList="downlineList"
                            :messageCenterType="'draft-message'"/>
          </template>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";

import { mapGetters } from "vuex";
import RepTreeModal from "@/components/RepTree/RepTreeModal.vue";

const DRAFT_MESSAGE_TAB_INDEX = 3;
export default {
  name: "SendMessageCompose",
  components: {RepTreeModal},
  props: {
    type: {
      type: String,
    },
    index: {
      required: false,
     },
      messageLog: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    value: "",
    states: Helper.getStates(),
    userTypes: Helper.messageUserTypes(),
    planCategory:[],
    form: {
      message: "",
      subject: "",
      userType: "",
      state: "",
      area_code: "",
      mid: "",
    },
    planOptionsLimit: 50,
    selectedPlans: [],
    receiver: [],
    files: [],
    users: [],
    plans: [],
    isPlanLoading: false,
    isUserLoading: false,
    userTypeText: "Broker/Member/Group List",
    userSearchQuery: "",
    planSearchQuery: "",
    isSend: false,
    mimeTypes: ["application/pdf", "image/jpeg", "image/jpg", "image/png"],
    saveToDraft: false,
    publishDraft: false,
    attachments: [],
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }, { header: 3 }],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image"],
          ['table', 'column-left', 'column-right', 'row-above', 'row-below', 'row-remove', 'column-remove'],

        ],
        imageResize: {
          //Add
          displayStyles: {
            //Add
            backgroundColor: "black",
            border: "none",
            color: "white",
          },
          modules: ["Resize", "DisplaySize", "Toolbar"],
        },
      /*'better-table': {
        operationMenu: {
          items: {
            unmergeCells: {
              text: 'Another unmerge cells name'
            }
          },
          color: {
            colors: ['green', 'red', 'yellow', 'blue', 'white'],
            text: 'Background Colors:'
          }
        }
      },*/
      },
      placeholder: "Enter content...",
    },
    downlineList: {},
    previewUsers : [],
    include_state: 1,
    include_plan: 1,
    include_member: 1,
    include_plan_category: 1,
    selectedStateList : [],
    selectedStatus: '',
    selectedGender: '',
    selectedLevel: null,
    statusList: [
      {
        'text': 'Active',
        'value': 'A'
      },
      {
        'text': 'Termed',
        'value': 'T'
      },
      {
        'text': 'Withdrawn',
        'value': 'W'
      }
    ],
    genderList: [
      {
        'text': 'Male',
        'value': 'M'
      },
      {
        'text': 'Female',
        'value': 'F'
      },
      {
        'text': 'Others',
        'value': 'O'
      }
    ],
    repLevel: [1,2,3,4,5,6,7,8,9],
    groupType: [
      {
        'text': 'Employer',
        'value': 'employer'
      },
      {
        'text': 'Agency',
        'value': 'agency'
      },
      {
        'text': 'Call Center',
        'value': 'call_center'
      },
      {
        'text': 'Group',
        'value': 'group'
      },
    ],
    selectedPlanCategoryList : [],
    include_rep_list: 1,
    selectedGroupType: [],
    minAge: 0,
    maxAge: 100,
    memberGreaterThen: 0,
    activeClientGreaterThen: 0
  }),
  methods: {
    filter: function () {
      this.getUsersList()
      this.$bvModal.hide('draft-advance-filter')
    },
    resetRepTree() {
      this.$store.commit("setSendRepMessageSelectedReps", []);
      this.$store.commit("setSendRepMessageCheckedRep", false);
    },
    renderMessageTab () {
      if (this.saveToDraft) {
        this.$emit('renderTabInfo', 'saveToDraft');
      }
      if (!this.saveToDraft) {
        this.$emit('renderTabInfo', 'send');
      }
    },
    checkType() {
      if (this.form.userType != "A") {
        this.resetRepTree();
        return;
      }
      if (this.messageCenterTabIndex != DRAFT_MESSAGE_TAB_INDEX) {
        //update vuex data for rep tree if only user is in draft message view
        return;
      }
      if (this.receiver.length) {
        this.$store.commit("setSendRepMessageSelectedReps", this.receiver);
        this.$store.commit("setSendRepMessageCheckedRep", true);
      }
    },
    async getDownlineList() {
        this.$store.dispatch("getRepInfo");
        let agentId = this.$store.getters.rep.id_for_tree
            ? this.$store.getters.rep.id_for_tree
            : this.$store.getters.repId;
        let url = `v2/get-rep-tree?agent_id=${agentId}`;
        let loader = this.$loading.show();
        await Api.get(url)
            .then((res) => {
              this.downlineList = res.data.data;
            })
            .catch(() => (this.downlineList = {}))
            .finally(() => {
              loader.hide();
            });
    },
    openAddRepModal() {
        this.$bvModal.show("repTreeModal");
        this.$emit("onGetDownlineCheckList", this.downlineList);
        this.$bvModal.hide('draft-advance-filter')
    },
    getUsersList() {

      this.receiver = [];
      this.users = [];
      /* set rep to empty on user change */
      //this.$store.commit("setSendRepMessageSelectedReps", []);
      //this.$store.commit("setSendRepMessageCheckedRep", false);
      this.getUsers(this.form.userType);
    },
    getUsersWithAreaCode() {
      this.receiver = [];
      this.users = [];
      // if (this.area_code.length > 4) {
      this.getUsers(this.form.userType);
      // }
    },
    getFilterUser(state = "", areaCode = "", userSearchQuery = "", planCategoryListQuery = '') {
      let url;
      url = `v2/get-user-list`;
      url += `?page_size=50`;
      if (state && state.length > 0) {
        state = `state=${state}`;
      }

      if (planCategoryListQuery && planCategoryListQuery.length > 0) {
        planCategoryListQuery = `&plan_category=${planCategoryListQuery}`;
      }


      if (areaCode.length > 0) {
        areaCode = `&area_code=${areaCode}`;
      }
      if (userSearchQuery) {
        userSearchQuery = "&user_name=" + userSearchQuery;
      }

      userSearchQuery += "&type=" + this.form.userType;
      userSearchQuery += "&include_plan="+ this.include_plan + "&include_state="+ this.include_state + "&include_plan_category="+ this.include_plan_category  + "&include_rep_list="+ this.include_rep_list
      let filterQuery = state || areaCode || userSearchQuery || planCategoryListQuery ? true : false;
      if (filterQuery) {
        return `${url}&${state}${areaCode}${userSearchQuery}${planCategoryListQuery}`;
      } else {
        return url;
      }
    },
    reset() {
      this.form = {
        message: "",
        subject: "",
        userType: "",
        area_code: "",
        state: "",
        mid: "",
      };
      this.users = [];
      this.files = [];
      this.receiver = [];
      this.userSearchQuery = "";
      this.planSearchQuery = "";
      this.selectedPlans = [];
      this.selectedStateList = [];
      this.selectedPlanCategoryList = [];
      this.$refs.observer.reset();
      this.$store.commit("setsendRepMessageSelectedReps", []);
      this.$store.commit("setSendRepMessageCheckedRep", false);
    },
    async sendMessage(previewModal = false) {
      let url = `v3/send-message`;
      this.isSend = true;
      this.form.state = this.selectedStateList.join()
      this.form.plan_category = this.selectedPlanCategoryList.join()
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }

      formData.append(`attachments`, JSON.stringify(this.attachments));
      // formData.append("plan_id", JSON.stringify(this.planIds));
      formData.append("plan_id", JSON.stringify(this.selectedPlans));
      formData.append("receiver_type", this.form.userType);
      formData.append("include_plan", this.include_plan);
      formData.append("include_state", this.include_state);
      formData.append("include_member", this.include_member);
      formData.append("state_list", this.form.state);
      formData.append("plan_category", this.form.plan_category);
      formData.append("include_plan_category", this.include_plan_category);
      formData.append("include_rep_list", this.include_rep_list);

      if (this.form.userType == "A") {
        formData.append(
            "rep_list",
            JSON.stringify(this.$store.getters.sendRepMessageSelectedReps)
        );
        formData.append("receiver", JSON.stringify(this.receiver));
        formData.append("active_client_greater_than", parseInt(this.activeClientGreaterThen));
        formData.append("level", this.selectedLevel);
      }

      if (this.form.userType == "G") {
        formData.append(
            "rep_list",
            JSON.stringify(this.$store.getters.sendRepMessageSelectedReps)
        );
        formData.append("receiver", JSON.stringify(this.receiver));
        formData.append("member_greater_than", parseInt(this.memberGreaterThen));
        formData.append("group_type", this.selectedGroupType);
      }

      if (this.form.userType == 'M') {
        formData.append(
            "rep_list",
            JSON.stringify(this.$store.getters.sendRepMessageSelectedReps)
        );
        formData.append("receiver", JSON.stringify(this.receiver));
        formData.append('min_age', parseInt(this.minAge));
        formData.append('max_age', parseInt(this.maxAge));
        formData.append('gender', this.selectedGender);
      }
      /* to save message to draft */
      formData.append("save_to_draft", +this.saveToDraft);
      /* to update message and publish */
      formData.append("publish_message", +this.publishDraft);
      formData.append("mid", this.form.mid);
      this.files.map((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      let loader = this.$loading.show({
        container: this.$refs.sendMessageContainer,
      });


      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.reset();
          this.$root.$emit("refreshMessage", this.saveToDraft);
          this.renderMessageTab()
        })
        .catch((err) => {
          // this.$root.$emit("refreshMessage", this.saveToDraft);
          // this.$emit("update:type", "new");
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          (this.saveToDraft = false),
            (this.publishDraft = false),
            (this.isSend = false);
          loader.hide();
          this.resetRepTree();
          this.$bvModal.hide('previewMessage');
        });
    },
    getUsersByUserType(type) {
      switch (type) {
        case "A":
          this.userTypeText = "Rep List";
          break;
        case "M":
          this.userTypeText = "Member List";
          break;
        case "G":
          this.userTypeText = "Group List";
          break;
        default:
          this.userTypeText = "Broker/Member/Group List";
      }
    },
    getUsers(type) {
      let url;
      // this.receiver = [];
      this.users = [];

      this.selectedStateList = this.selectedStateList.filter(state => state !== "");
      this.selectedPlanCategoryList = this.selectedPlanCategoryList.filter(cat => cat !== "");

      this.form.state = this.selectedStateList.join()
      url = this.getFilterUser(
        this.form.state,
        this.form.area_code,
        this.userSearchQuery,
        this.selectedPlanCategoryList
      );
      let user = [{ id: "ALL", name: "ALL", email: null, user_type: "" }];
      this.getUsersByUserType(type);
      this.isUserLoading = true;
      let rep_list = this.$store.getters.sendRepMessageSelectedReps
      let params = {};

      if (this.form.userType == 'M') {
        params['min_age'] = parseInt(this.minAge)
        params['max_age'] = parseInt(this.maxAge)
        params['gender'] = this.selectedGender
      }

      if (this.form.userType == 'G') {
        params['member_greater_than'] = parseInt(this.memberGreaterThen)
        params['group_type'] = this.selectedGroupType
      }

      if (this.form.userType == 'A') {
        params['active_client_greater_than'] = parseInt(this.activeClientGreaterThen)
        params['level'] = this.selectedLevel
      }
      return Api.post(url, { plan_id: this.selectedPlans, state_list: this.form.state, rep_list: rep_list, ...params})
        .then((res) => {
          // this.users = [];
          // this.receiver = [];
          switch (type) {
            case "A":
              this.users = res.data.data.rep;
              break;
            case "M":
              this.users = res.data.data.member;
              break;
            case "G":
              this.users = res.data.data.group;
              break;
            default:
              this.users = [];
          }
          if (this.users.length > 0) {
            this.users = [...user, ...this.users];
          } else {
            this.users = [];
          }
        })
        .catch(() => {
          this.users = [];
        })
        .finally(() => {
          this.isUserLoading = false;
        });
    },
    getPlanList(include_plan_category) {
        let url = "v2/rep/get-plans";
        url += `?page_size=25`;
        if (this.planSearchQuery.length >= 3) {
          url += "&plan_name=" + this.planSearchQuery;
        }

        // let planCategory = Object.keys(this.selectedPlanCategoryList).map(function(k){return this.selectedPlanCategoryList[k]}).join(",");
        let planCategory = this.selectedPlanCategoryList.join()
        url += "&include_plan_category=" + include_plan_category + "&plan_category=" + planCategory
        url += "&include_rep_list=" + this.include_rep_list + "&rep_list=" + this.$store.getters.sendRepMessageSelectedReps.join()
        this.isPlanLoading = true;
        Api.get(url)
            .then((res) => {
              this.plans = res.data.data;
            })
            .catch(() => {
              this.plans = [];
            })
            .finally(() => {
              // this.selectedPlans = []
              this.isPlanLoading = false;
            });
    },
    searchPlan(query) {
      this.planSearchQuery = query;
      if (this.planSearchQuery && this.planSearchQuery.length >= 3) {
        this.getPlanList(this.include_plan_category);
      }
    },
    searchUser(query) {
      this.userSearchQuery = query;
      if (this.userSearchQuery && this.userSearchQuery.length >= 3) {
        this.getUsers(this.form.userType);
      }
    },
    removeAttachments(attachmentID) {
      this.attachments = this.attachments.filter(
        (e) => e.attachment_id != attachmentID
      );
    },
    openPreviewMessageModal() {
      this.previewUsers = [];
      let loader = this.$loading.show();
      let url = "v2/get-member-rep-group-name";
      let formData = {};

      if (this.form.userType == "A") {
        let selectedReps = this.$store.getters.sendRepMessageSelectedReps;
        formData = {"id" : selectedReps , 'type' : this.form.userType};
      } else {
        let otherReceiver = this.receiver;
        formData = {"id" : otherReceiver, 'type' : this.form.userType };
      }

      Api.post(url, formData)
        .then((res) => {
          if (this.form.userType == "A") {
              let responseData = res.data.data;
              responseData.forEach(agent => {
                this.previewUsers.push(agent.rep_full_name+' ('+ agent.agent_code+')');
              });
          }else if (this.form.userType == "G") {
              let responseData = res.data.data;
              responseData.forEach(group => {
                this.previewUsers.push(group.gname+' ('+ group.gcode+')');
              });
          }
          else{
            this.previewUsers = res.data.data;
          }
          this.$refs['previewMessage'].show();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
          // this.$notify("static message", "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    getPlanCategoryList() {
        let url = "v2/get-plan-category";

        this.isPlanCategoryLoading = true;
        Api.get(url)
            .then((res) => {
              this.planCategory = res.data.data;
            })
            .catch(() => {
              this.planCategory = [];
            })
            .finally(() => {
              this.isPlanCategoryLoading = false;
            });
    },
    refreshListing(include) {
      console.log(include)
      this.include_rep_list = include
      this.getPlanList(this.include_plan_category);
      this.getUsers(this.form.userType);
    },
    updateMinAge (e) {
      this.minAge = e
    },
    updateMaxAge (e) {
      this.maxAge = e
    },
    updateGroupMember (e) {
      this.memberGreaterThen = e
    },
    updateActiveClient (e) {
      this.activeClientGreaterThen = e
    },
    clearFilter () {
      this.users = [];
      this.files = [];
      this.form.area_code = ''
      this.receiver = [];
      this.userSearchQuery = "";
      this.planSearchQuery = "";
      this.selectedPlans = [];
      this.selectedStateList = [];
      this.selectedPlanCategoryList = [];
      this.$refs.observer.reset();
      this.$store.commit("setsendRepMessageSelectedReps", []);
      this.$store.commit("setSendRepMessageCheckedRep", false);

      if (this.form.userType == 'M') {
        this.minAge = 0
        this.maxAge = 100
        this.selectedGender = ''
      }

      if (this.form.userType == 'G') {
        this.memberGreaterThen = 0
        this.selectedGroupType = []
      }

      if (this.form.userType == 'A') {
        this.activeClientGreaterThen = 0
        this.selectedLevel = null
      }

      this.resetRepTree();
      this.getUsersList();
    }
  },
  mounted() {
    this.getPlanCategoryList()
    this.getPlanList(this.include_plan_category);
    this.getDownlineList();
    this.$store.commit("setSendRepMessageSelectedReps", []);
    this.$store.commit("setSendRepMessageCheckedRep", false);
    this.checkType();
  },
  computed: {
    planIds() {
      return this.selectedPlans.map((plan) => plan.plan_id);
    },
    ...mapGetters({
      sendRepMessageSelectedReps: "sendRepMessageSelectedReps",
      sendRepMessageCheckedRep: "sendRepMessageCheckedRep",
      messageCenterTabIndex: "messageCenterTabIndex",
    }),
  },
  watch: {
    messageLog: async function (newVal, oldVal) {
      if (newVal?.subject) {
        this.form = {
          message: newVal.message,
          subject: newVal.subject,
          userType: newVal.receiver_type,
          area_code: newVal?.area_code,
          state: newVal?.state,
          mid: newVal?.mid,
        };

        this.selectedPlans = newVal?.plan_id.map((e) => {
          return { plan_id: e };
        });
        await this.getUsers(newVal.userType);
        this.receiver = newVal?.receiver_new;
        this.attachments = newVal?.attachments;
        this.include_member = newVal?.include_member
        this.include_plan = newVal?.include_plan
        this.include_state = newVal?.include_state
        this.selectedStateList = newVal?.state.split(',')
        this.selectedPlanCategoryList = newVal?.plan_category
        this.include_plan_category = newVal?.include_plan_category
        this.include_rep_list = newVal?.include_rep_list

        if (this.form.userType == 'M') {
          this.minAge = newVal?.min_age
          this.maxAge = newVal?.max_age
          this.selectedGender = newVal?.gender
        }

        if (this.form.userType == 'G') {
          this.memberGreaterThen = newVal?.member_greater_than
          this.selectedGroupType = newVal?.group_type
        }

        if (this.form.userType == 'A') {
          this.activeClientGreaterThen = newVal?.active_client_greater_than
          this.selectedLevel = newVal?.level
        }

        if(this.form.userType != "A") {
          this.$store.commit("setSendRepMessageSelectedReps", newVal?.rep_list);
          this.$store.commit("setSendRepMessageCheckedRep", true);
        } else {
          this.checkType();
        }


      }
    },
    planSearchQuery: function (newQuery) {
      if (!newQuery) {
        this.getPlanList(this.include_plan_category);
      }
    },
    userSearchQuery: function (newQuery) {
      if (!newQuery) {
        this.getUsers(this.form.userType);
      }
    },
    "$store.getters.sendRepMessageSelectedReps": {
      handler: function(data) {
        if(this.form.userType != 'A' && this.form.userType) {
         this.getPlanList();
         this.getUsers(this.form.userType);
        }

      },
      immediate: true, // provides initial (not changed yet) state
    },
    selectedPlanCategoryList: function(){
      /*eslint-disable*/
        this.getPlanList(this.include_plan_category);
    },
    index: function (newVal, oldVal) {
      if (newVal != oldVal && (newVal == 3)) {
        this.getPlanCategoryList()
        this.getPlanList(this.include_plan_category);
        this.getDownlineList();
        this.$store.commit("setSendRepMessageSelectedReps", []);
        this.$store.commit("setSendRepMessageCheckedRep", false);
        this.reset();
        this.checkType();
      }
    }
  },
};
</script>
<style>
.invalid-block {
  display: block;
}
.save-to-draft {
  width: 20%;
  margin: auto;
}
.save-to-draft-check {
  height: 12px;
  margin-left: 1.5%;
  transform: scale(1.5);
}
.ql-container {
  height: 200px !important;
}
.header-popup {
  margin: auto;
}
.popUp-details label {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
.popup_input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #adadad;
}
.popup_input {
  font-size: 14px;
  padding: 8px 8px 8px 15px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  color: #35495e;
}
.popup_input:focus,
.popup_input:visited,
.popup_input:focus-visible
 {
  border-color:  #e8e8e8;
  outline: none;

}

.add-filtre-btn {
  max-width: 100%;
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
  border-radius: 6px;
  color: #fff;
  outline: none;
  border: 0;
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem;
  margin-top: 10px;
}
.add-filtre-btn:hover {
  color: white;
  box-shadow: 0px 8px 20px -13px rgb(0 0 0 / 69%)
}

.add-filtre-icon:focus {
  box-shadow: none;
}
#MsgContentDisplaydraft___BV_modal_footer_.modal-footer{
  display: none;
}
.preview-user {
    padding-bottom: 1rem;
}
.preview-user span {
    font-size: 13px;
    display: inline-block;
    background: #03c3ec;
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    margin: 10px 15px 0 0;
    word-break: break-all;
}
.advance-filter .multiselect{
  width: 100% !important;
  margin: 0 !important;
}
.advance-filter label{
  display: none;
}
@media(max-width:540px){
  .sendMessage-Formcard{
    box-shadow: inherit !important;
  }
}

.include_label {
  display: block;
}
.user_label label {
  display: none;
}

.range-custom-range {
  cursor: pointer;
  width: 100%;
}
.filter-label {
  font-size: 14px;
}
</style>
