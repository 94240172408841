var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "messageMemberView",
      "centered": "",
      "size": "md",
      "title": "Member List",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "message-member-view-header text-center"
        }, [_c('h4', [_vm._v("Member List")])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "msg-user-list-popup-wrap"
  }, _vm._l(_vm.members, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "msg-user-list-popup"
    }, [_c('div', {
      staticClass: "img"
    }, [_c('img', {
      attrs: {
        "src": item.image ? item.image : _vm.dummyImage,
        "alt": ""
      }
    })]), _c('span', [_vm._v(_vm._s(item.name))]), _c('p', [_vm._v(_vm._s(item.email))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }