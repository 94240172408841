<template>
  <b-modal id="addMessage" centered size="lg" no-close-on-backdrop>
    <template #modal-header>
      <div class="addMessage-header text-center">
        <h4>Send Email</h4>

        <b-button variant="close-btn" @click="reset">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="sendMessage-Formcard">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.stop.prevent="handleSubmit(sendMessage)"
            ref="sendMessageContainer"
          >
            <div class="form-group row">
              <input-multi-select
                :vmodel.sync="userType"
                labelName="Message For"
                :options="userTypes.map((type) => type.value)"
                :custom-label="
                  (opt) => userTypes.find((x) => x.value == opt).text
                "
                @onInput="getFilterUser()"
                :rules="{ required: true }"
                formGroupClass="col-xxl-4 mb-3"
                placeholder="Select User Type"
              />

              <input-multi-select
                :vmodel.sync="status"
                labelName="Status"
                :options="statuses.map((type) => type.value)"
                :custom-label="
                  (opt) => statuses.find((x) => x.value == opt).text
                "
                @onInput="getFilterUser()"
                formGroupClass="col-xxl-4 col-xl-4 col-lg-12 col-md-4 mb-4"
                placeholder="Select User Status"
              />

              <input-multi-select
                :vmodel.sync="state"
                labelName="State"
                :options="states.map((type) => type.value)"
                :custom-label="(opt) => states.find((x) => x.value == opt).text"
                @onInput="getFilterUser()"
                formGroupClass="col-xxl-4 mb-3"
                placeholder="Select State"
              />
            </div>
            <div class="form-group row">
              <div class="col-xxl-4 col-xl-4">
                <label>Age</label>
                <div class="row">
                  <div
                    class="col-xxl-6 col-xl-6 col-lg-12 col-md-6 col-sm-6 mb-4"
                  >
                    <input
                      v-model="age_from"
                      type="number"
                      placeholder="From"
                      class="sendmsg-field"
                      @input="filterByAge()"
                    />
                  </div>
                  <div
                    class="col-xxl-6 col-xl-6 col-lg-12 col-md-6 col-sm-6 mb-4"
                  >
                    <input
                      v-model="age_to"
                      type="number"
                      placeholder="To"
                      class="sendmsg-field"
                      @input="filterByAge()"
                    />
                  </div>
                  <div v-if="ageErrorText" class="col-12 text-red">
                    {{ ageErrorText }}
                  </div>
                </div>
              </div>

              <div class="col-xxl-8 col-xl-8 mb-4">
                <input-multi-select-multiple
                  :vmodel.sync="receiver"
                  :labelName="userTypeText"
                  :activeData.sync="users"
                  :rules="{ required: true }"
                  :options="users.map((type) => type.email)"
                  :customLabel="
                    (opt) => users.find((x) => x.email == opt).full_name
                  "
                  labelKey="email"
                  :optionsLimit="size"
                  :localSearch="false"
                  @open="getUsers(userType)"
                  @search="searchUsers"
                  :isLoading="searchLoading"
                />
              </div>
            </div>
            <input-text
              :rules="{ required: true }"
              labelFor="message-subject"
              labelName="Subject"
              inputName="subject"
              :vmodel.sync="form.subject"
              formGroupClass="form-group mb-4"
            />

            <b-form-group>
              <label id="attachment" for="attachment"> Attachment</label>
              <b-form-file
                browse-text="Choose File"
                v-model="attachment"
                placeholder="Attachment"
                accept="png,jpg,xlx,xlsx,pdf,txt,csv,jpeg"
              >
              </b-form-file>
            </b-form-group>
            <div class="form-group mb-4">
              <validation-provider
                name="Message"
                rules="required"
                v-slot="{ errors }"
              >
                <label for=""
                  >Message <span class="required-indc">*</span></label
                >
                <ckeditor
                  :editor="editor"
                  v-model="form.message"
                  :config="editorConfig"
                />
                <div class="invalid-block invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
            <div class="row justify-content-md-end">
              <div class="col-xxl-3">
                <button type="submit" class="univ-btn">
                  {{ isSend ? "Sending Email...." : "Send Email" }}
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "AddMessage",
  data: () => ({
    editor: ClassicEditor,
    editorConfig: Helper.simpleEditorConfig(),
    states: Helper.getStates(),
    userTypes: Helper.messageUserTypes(),
    form: {
      message: "",
      subject: "",
      type: "",
      age_from: "",
      age_to: "",
      state: "",
      status: "",
    },
    userType: "",
    age_from: "",
    age_to: "",
    state: "",
    status: "",
    statuses: [],
    receiver: [],
    attachment: null,
    users: [],
    userTypeText: "Broker/Member/Group List",
    isSend: false,
    mimeTypes: ["application/pdf", "image/jpeg", "image/jpg", "image/png"],
    ageErrorText: "",
    userListUrl: "v2/custom-message/get-users",
    tempUserListUrl: "",
    searchLoading: false,
    size: 50,
  }),
  methods: {
    searchUsers(value) {
      if (value.length > 3) {
        this.getUsers(this.userType, value);
      }
    },
    sendMessage() {
      let url;
      this.isSend = true;
      url = `v2/custom-message/send`;
      let formData = new FormData();

      if (this.receiver.includes("All")) {
        this.receiver = [""];
         formData.append("send_to_all ", true);
      } else {
        this.receiver = this.receiver.filter((e) => {
          return e !== "All";
        });
      }

      this.form = {
        message: this.form.message,
        subject: this.form.subject,
        type: this.userType,
        age_from: this.age_from,
        age_to: this.age_to,
        state: this.state,
        status: this.status,
      };

      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      if (this.attachment) {
        formData.append("attachment", this.attachment);
      }

      this.receiver.map((data, index) => {
        formData.append(`email[${index}]`, data);
      });

      // this.files.map((file, index) => {
      //   formData.append(`attachment[${index}]`, file);
      // });

      let loader = this.$loading.show({
        container: this.$refs.sendMessageContainer,
      });

      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.reset();
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
    filterByAge() {
      setTimeout(() => {
        const [from, to] = [parseInt(this.age_from), parseInt(this.age_to)];
        if (from > to) {
          this.ageErrorText = "Age To must be greater than Age From.";
          [this.age_from, this.age_to] = ["", ""];
          setTimeout(() => {
            this.ageErrorText = "";
          }, 4000);
        } else {
          this.ageErrorText = "";
          this.getFilterUser();
        }
      }, 2000);
    },
    getFilterUser() {
      this.userListUrl = `v2/custom-message/get-users?size=${this.size}`;
      let [state, status, ageFrom, ageTo, ageFilter] = [
        this.state,
        this.status ? this.status : "",
        this.age_from,
        this.age_to,
        "",
      ];

      if (this.userType) {
        this.getUsersByUserType(this.userType);
      }
      if (status && status.length > 0) {
        status = `&status=${status}`;
      }
      if (state && state.length > 0) {
        state = `&state=${state}`;
      }
      if (ageFrom || ageTo) {
        ageFrom = ageFrom ? `&age_from=${ageFrom}` : "";
        ageTo = ageTo ? `&age_to=${ageTo}` : "";
        ageFilter = `${ageFrom}${ageTo}`;
      }
      let filterQuery =
        this.userType || state || status || ageFilter ? true : false;
      if (filterQuery) {
        this.userListUrl = `${this.userListUrl}&type=${this.userType}${status}${state}${ageFilter}`;
      } else {
        this.userListUrl = `${this.userListUrl}`;
      }
      this.getUsers(this.userType)
    },
    reset() {
      this.form = {
        message: "",
        subject: "",
      };
      this.age_from = "";
      this.age_to = "";
      this.state = "";
      this.users = [];
      this.attachment = null;
      this.receiver = [];
      this.userType = "";
      this.$refs.observer.reset();
      this.userListUrl = "v2/custom-message/get-users";
      this.tempUserListUrl = "";
      this.$bvModal.hide("addMessage");
    },
    getUsersByUserType(type) {
      switch (type) {
        case "A":
          this.userTypeText = "Rep List";
          this.statuses = [
            { value: "Active", text: "Active" },
            { value: "Pending", text: "Pending" },
            { value: "Disabled", text: "Disabled" },
          ];
          break;
        case "M":
          this.userTypeText = "Member List";
          this.statuses = [
            { value: "Active", text: "Active" },
            { value: "Withdrawn", text: "Withdrawn" },
            { value: "Termed", text: "Termed" },
          ];
          break;
        case "G":
          this.userTypeText = "Group List";
          this.statuses = [
            { value: "Active", text: "Active" },
            { value: "Inactive", text: "Inactive" },
          ];
          break;
        default:
          this.userTypeText = "Broker/Member/Group List";
          this.statuses = [];
      }
    },
    async getUsers(type, value = null) {
      let url;
      url = this.userListUrl;
      if (value) {
        url = url + `&name=${value}`;
      }
      let user = [{ id: "All", full_name: "All", email: "All", user_type: "" }];
      if (this.tempUserListUrl !== url) {
        //setting for first time
        this.tempUserListUrl = url;
        this.receiver = [];
        this.users = [];
        this.searchLoading = true;
        await Api.get(url)
          .then((res) => {
            switch (type) {
              case "A":
                this.users = res.data.data.rep;
                break;
              case "M":
                this.users = res.data.data.member;
                break;
              case "G":
                this.users = res.data.data.group;
                break;
              default:
                this.users = [];
            }
            if (this.users.length > 0) {
              this.users = [...user, ...this.users];
            } else {
              this.users = [];
            }
          })
          .catch(() => {
            this.users = [];
          })
          .finally(() => {
            this.searchLoading = false;
          });
      }
    },
  },
};
</script>