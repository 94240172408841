<template>
  <span :class="`spilt ${className} card-${rep.agent_id}`">
    <div class="displayLine">
      <template v-if="rep.downline && rep.downline.length > 0">
        <input
          type="checkbox"
          :id="`allDownline-${rep.agent_id}`"
          @change="checkAllDownline(rep.downline, rep.agent_id)"
          title="Select All Downline"
          class="mr-3 custom-check"
        />
      </template>
      <input
        type="checkbox"
        :id="`upline-${rep.agent_id}`"
        :class="`upline-${parentUplineId} rep-checkbox`"
        v-model="selectedReps"
        :value="rep.agent_id"
        @change="checkUpline(rep.agent_id)"
        title="Select Rep"
        class="mr-3 custom-check"
      />
      {{ rep.agent_id }}
      {{
        `${rep.agent_name} - Level
                        ${rep.agent_level}
                        (${rep.agent_code})`
      }}
      <template v-if="rep.downline && rep.downline.length > 0">
        <send-message-rep-toggle :uplineId="rep.agent_id" />
      </template>
      <br /><small class="email-text">{{ rep.agent_email }}</small>
    </div>
  </span>
</template>
<script>
import SendMessageRepToggle from "./SendMessageRepToggle.vue";
export default {
  components: { SendMessageRepToggle },
  name: "SendMessageRepTreeCard",
  props: {
    rep: {
      required: true,
      type: Object,
      default: null,
    },
    className: {
      required: false,
      type: String,
      default: "",
    },
    parentUplineId: {
      required: false,
      type: Number,
    },
  },
  data: () => ({
    selectedReps: [],
  }),
  methods: {
    removeItems(array, itemToRemove) {
      return array.filter((v) => {
        return !itemToRemove.includes(v);
      });
    },
    setWithStore(newSelectedReps) {
      let storedReps = this.$store.getters.sendRepMessageSelectedReps;
      this.$store.commit(
        "setSendRepMessageSelectedReps",
        new Set([...storedReps, ...newSelectedReps])
      );
    },
    checkUpline(uplineId) {
      // let selectedReps = this.selectedReps;
      // console.log("se",selectedReps);
      let checked = document.getElementById(`upline-${uplineId}`).checked;
      if (checked) {
        let selectedReps = [...new Set([uplineId])];
        this.setWithStore(selectedReps);
        this.$emit("onCheckAllDownline", [...new Set([uplineId,...this.$store.getters.sendRepMessageSelectedReps])]);
      } else {
        let uplineArray = [];
        uplineArray = [...new Set([uplineId, ...uplineArray])];
        this.$emit("onRemoveDownlines", uplineArray);
      }
    },

    removeAll(uplineId, downlines) {
      let downlineAgents = downlines.map((downline) => downline.agent_id);
      this.$emit("onRemoveDownlines", [
        ...new Set([uplineId, ...downlineAgents]),
      ]);
      /**
       * uncheck all downline selcect checbox
       */
      document.getElementById(`allDownline-${uplineId}`).checked = false;
      if (downlines.length > 0) {
        downlines.forEach((downline) => {
          if (downline.downline && downline.downline.length > 0) {
            this.removeAll(downline.agent_id, downline.downline);
          }
        });
      }
    },
    checkAllDownline(downlines, uplineId, checkDownline = false) {
      let downlineAgents = [];
      let selectedReps = this.selectedReps;
      let checked;
      if (checkDownline) {
        checked = document.getElementById(
          `allDownline-${uplineId}`
        ).checked = true;
      } else {
        checked = document.getElementById(`allDownline-${uplineId}`).checked;
      }
      downlineAgents = downlines.map((downline) => downline.agent_id);
      let div = document.getElementById(`send-message-node-inner-${uplineId}`);
      if (checked) {
        selectedReps = [
          ...new Set([uplineId, ...downlineAgents, ...selectedReps]),
        ];
        div.classList.remove("d-none");
        this.setWithStore(selectedReps);
        this.$emit("onCheckAllDownline", selectedReps);
        downlines.forEach((downline) => {
          if (downline.downline && downline.downline.length > 0) {
            this.checkAllDownline(downline.downline, downline.agent_id, true);
          }
        });
      } else {
        /**Remove all realated downlines */
        this.removeAll(uplineId, downlines);
      }
    },
    encodedId(id) {
      return btoa(id);
    },
  },
};
</script>
