<template>
  <div>
    <div class="email-tbl h-100">
      <div class="search-wrap">
        <input class="search-input" v-model="searchQuery" placeholder="Search "/>
        <button @click="searchMessageList"><i class="fa fa-search"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      required: false
    }
  },
  data () {
    return {
      searchQuery: null
    }
  },
  methods: {
    searchMessageList () {
      let app = this
      app.$emit ('searchMessageList', app.searchQuery)
    }
  },
  watch: {
    searchQuery(newQuery) {
      if (!newQuery) {
        let app = this
        app.$emit('searchMessageList', app.searchQuery)
      }
    },
    index: {
      handler:function() {
        this.searchQuery = null
      },
      immediate:true
    }
  }
}
</script>
<style scoped>

</style>
