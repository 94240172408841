<template>
  <div class="sendMessage-wrap draft-message-wrap">
    <div class="row">
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
        <div>
          <search-filter @searchMessageList="searchMessageList" :index="index"/>
        </div>
        <div class="sendMessage-card mt-3">
          <div class="sendMessage_headTitle">
            <h2>Draft Messages</h2>
            <!-- <b-button class="add-msg" @click="composeMessage">Add New</b-button> -->
          </div>
          <message-list
            :rows.sync="rows"
            @onClickedItem="getMessage"
            type="sent"
          />
        </div>
        <div class="table-pagination">
          <pagination :rows.sync="rows" />
        </div>
      </div>

      <template v-if="showMessage">
        <message-content
          :message="showMessage"
          type="draft"
          :messageLog="messageLog"
          @onSuccess="getAll"
          @onGetDownlineCheckList="downlineListHandler"
        />
         <template v-if="index == 3">
          <send-message-rep-tree :downlineList="downlineList" />
        </template>
      </template>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import Api from "@/resource/Api";
import MessageList from "../partials/MessageList";
import MessageContent from "../partials/MessageContent.vue";
import SendMessageRepTree from '../SendMessageRep/SendMessageRepTree.vue';
import searchFilter from "../partials/search-filter.vue";

export default {
  name: "DraftMessage",
  components: {
    MessageList,
    MessageContent,
    SendMessageRepTree,
    searchFilter
  },
    props: {
    index:{
      required: false,
    },
  },
  data: () => ({
    rows: [],
    canCompose: false,
    showMessage: null,
    messageLog : {},
    downlineList: {},
    searchQuery: null
  }),
  methods: {
     downlineListHandler(downlineList) {
      this.downlineList = downlineList;
    },
    composeMessage() {
      this.canCompose = true;
      if (this.showMessage) {
        document
          .querySelector(`.message-${this.showMessage.mid}-list`)
          .classList.remove("active-list");
      }
      this.showMessage = null;
    },
    getMessage(item) {
      this.getMessageFilters(item.mid);
      this.canCompose = false;
      this.showMessage = item;
    },
    searchMessageList (data) {
      let app = this
      app.searchQuery = data
      app.getAll(app.searchQuery)
    },
    getAll(searchQuery) {
     if (this.index == 3) {
       let url = "v2/get-unpublished-message-list";
       let loader;
       if (searchQuery) {
         const encodedQuery = encodeURIComponent(searchQuery);
         url += `?search=${encodedQuery}`;
       }
         loader = this.$loading.show();
       Api.get(url)
           .then((res) => {
             this.rows = res.data;
             this.showMessage = this.rows.records[0];
             if (this.rows.records.length < 1) {
               this.canCompose = true;
               this.showMessage = null;
             } else {
               this.canCompose = false;
             }
             this.getMessageFilters(this.showMessage.mid)
           })
           .catch(() => {
             this.rows = [];
             this.showMessage = null;
           })
           .finally(() => {
             loader.hide();
           });
     }
   },
  getMessageFilters(mid) {
    if (this.index == 3) {
      const url = "v2/get-message-filters/" + mid;
      let loader = this.$loading.show();
      Api.get(url)
          .then((res) => {
            this.messageLog = (res.data?.data?.request_filters)
          })
          .finally(() => {
            loader.hide();
          });
      }
    }
  },
    mounted() {
     this.$root.$on("refreshMessage", (data) => {
        this.getAll()
    });
  },
  created() {
    this.getAll();
  },
  watch: {
    rows: function (newData, oldData) {
      if (newData != oldData) {
        this.showMessage =
          newData.records.length > 0 ? newData.records[0] : null;
      }
    },
    index : function(newVal, oldVal){
      if(newVal != oldVal && newVal == 3 ) {
          this.getAll();
      }
    }
  },
};
</script>
<style>
.receiveMessage-detailcard {
  position: relative;
}
.receiveMessage-detailcard .recieved-actions {
  margin-top: -8px;
}
.receiveMessage-wrap .receiveMessage-detailcard .recieved-actions {
  position: absolute;
  right: 20px;
}
.receiveMessage-detailcard .rcv-msgHead .sndr-head {
  margin-bottom: 10px;
}
.sendMessage-wrap .receiveMessage-detailcard .recieved-actions {
  position: relative;
  margin-bottom: 5px;
}
.sendMessage-wrap .receiveMessage-detailcard .rcv-msgHead .msg-member-list {
  margin-bottom: 20px;
}
.sendMessage-card .msg-list .message-list .msg-userIcon {
  border-radius: 0;
}
.sendMessage-card .msg-list .message-list .msg-userIcon img {
  border-radius: 0;
  margin-left: 5px;
  width: 35px;
  height: 35px;
}
.receiveMessage-detailcard .rcv-msgHead .sndr-head p {
  font-size: 12px!important;
  font-weight: 500;
  color: #ccc;
}
.receiveMessage-detailcard .rcv-msgHead h2, .receiveMessage-detailcard .rcv-msgHead h3 {
  font-size: 16px;
}
.sendMessage-wrap .page-link {
    padding: 0.375rem 0.3rem;
    font-size: 13px;
}
/* .sendMessage-wrap .table-pagination {
    margin-top: -20px;
} */
</style>
