var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "receiveMessage-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-4 col-lg-4 col-md-4"
  }, [_c('div', [_c('search-filter', {
    attrs: {
      "index": _vm.index
    },
    on: {
      "searchMessageList": _vm.searchMessageList
    }
  })], 1), _c('div', {
    staticClass: "receiveMessage-card mt-3"
  }, [_vm._m(0), _c('message-list', {
    attrs: {
      "rows": _vm.rows,
      "type": "receive"
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      },
      "onClickedItem": _vm.getMessage
    }
  }), _c('div', {
    staticClass: "table-pagination"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)], 1)]), _c('message-content', {
    attrs: {
      "message": _vm.showMessage,
      "type": "receive"
    },
    on: {
      "onSuccess": _vm.getAll
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "receiveMessage_headTitle"
  }, [_c('h2', [_vm._v("Recieved Messages")])]);
}]

export { render, staticRenderFns }