<template>
  <b-modal
    id="messageMemberView"
    centered
    size="md"
    title="Member List"
    hide-footer
  >
   <template #modal-header>
      <div class="message-member-view-header text-center">
        <h4>Member List</h4>
        <!-- <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button> -->
      </div>
    </template>
    <div class="msg-user-list-popup-wrap">
      <div
        class="msg-user-list-popup"
        v-for="(item, index) in members"
        :key="index"
      >
        <div class="img">
          <img :src="item.image ? item.image : dummyImage" alt="" />
        </div>
        <span>{{ item.name }}</span>
        <p>{{ item.email }}</p>
      </div>
    </div>
  </b-modal>
</template>
<script>
import dummyImage from "@/assets/images/user.svg";
export default {
  name: "MessageMemberView",
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dummyImage,
  }),
   methods: {
    closeModal() {
      this.$bvModal.hide("messageMemberView");
    },
  },
};
</script>

<style scoped>
.msg-user-list-popup-wrap {
  max-height: 500px;
  overflow-y: auto;
  margin: -15px;
}
.msg-user-list-popup-wrap {
  max-height: 500px;
  overflow-y: auto;
  margin: -15px;
}
.msg-user-list-popup {
  margin-bottom: 0px;
  padding: 15px;
  padding-bottom: 25px;
  border-bottom: 2px solid #f3f7fa;
}
.msg-user-list-popup:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 25px;
}
.msg-user-list-popup .img {
  display: inline-block;
  height: 58px;
  width: 58px;
  border-radius: 50px;
  overflow: hidden;
  vertical-align: top;
  margin-right: 20px;
}
.msg-user-list-popup .img img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  height: 58px;
  margin-bottom: 0;
  padding-bottom: 0;
  display: inline-block;
}
.msg-user-list-popup span {
  font-weight: bold;
  font-size: 17px;
}
.msg-user-list-popup p {
  display: block;
  margin-left: 80px;
  margin-top: -30px;
  font-size: 13px;
  color: #707070;
}


.message-member-view-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.message-member-view-header p {
  font-size: 14px;
  color: #2c3f58;
}
.message-member-view-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>