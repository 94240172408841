var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "msg-list",
    class: {
      'display-none': _vm.checkRowLength()
    }
  }, [_c('vue-scroll', {
    attrs: {
      "ops": _vm.msgScroll
    }
  }, [_c('ul', _vm._l(_vm.rows.records, function (row) {
    return _c('li', {
      key: row.mid,
      staticClass: "message-list",
      class: _vm.listClass(row.mid),
      on: {
        "click": function click($event) {
          return _vm.setMessage(row);
        }
      }
    }, [row ? _c('message-list-content', {
      attrs: {
        "row": row,
        "type": _vm.type
      }
    }) : _vm._e()], 1);
  }), 0)])], 1), _vm.checkRowLength() ? _c('div', {
    staticClass: "text-center mt-4"
  }, [_vm._v(" No result found ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }