<template>
  <div>
    <div class="msg-list" :class="{'display-none': checkRowLength()}">
      <vue-scroll :ops="msgScroll">
        <ul>
          <li
              v-for="row in rows.records"
              :key="row.mid"
              :class="listClass(row.mid)"
              @click="setMessage(row)"
              class="message-list"
          >
            <message-list-content :row="row" :type="type" v-if="row"/>
          </li>
        </ul>
      </vue-scroll>
    </div>
    <div  class="text-center mt-4" v-if="checkRowLength()">
      No result found
    </div>
  </div>
</template>
<script>
import MessageListContent from "./MessageListContent.vue";
export default {
  name: "MessageList",
  components: { MessageListContent },
  props: {
    rows: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    msgScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 628,
      },
      bar: {
        background: "#ddd",
      },
    },
    currentItemId: null,
  }),
  methods: {
    listClass(mId) {
      let activeClass = this.currentItemId == mId ? "active-list" : "";
      return `${activeClass} message-${mId}-list`;
    },
    setMessage(item) {
      this.currentItemId = item.mid;
      //readding active class
      document
        .querySelector(`.message-${item.mid}-list`)
        .classList.add("active-list");
      this.$emit("onClickedItem", item);
    },
    checkRowLength () {
      if (this.rows.length < 1) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    rows: function (newData) {
      if (this.$route.params.selectedReceivedMessage) {
        this.currentItemId = this.$route.params.selectedReceivedMessage.mid;
      } else {
        this.currentItemId =
          newData.records.length > 0 ? newData.records[0].mid : null;
      }
    },
  },
};
</script>

<style scoped>
.display-none {
  display: none;
}
</style>
