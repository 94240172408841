<template>
  <div class="sendMessage-wrap">
    <div class="row">
      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
        <div>
          <search-filter @searchMessageList="searchMessageList" :index="index"/>
        </div>
        <div class="sendMessage-card mt-3">
          <div class="sendMessage_headTitle">
            <h2>Sent Messages</h2>
            <!--            <b-button class="add-msg" @click="composeMessage">Add New</b-button>-->
          </div>
          <message-list
              :rows.sync="rows"
              @onClickedItem="getMessage"
              type="sent"
              v-b-modal.MsgContentDisplaysent
          />
        </div>
        <div class="table-pagination">
          <pagination :rows.sync="rows" :showPageRecord="false" />
        </div>
      </div>
      <template v-if="canCompose">
        <send-message-compose
            @onSuccess="getAll"
            @onGetDownlineCheckList="downlineListHandler"
            type="sent"
        />
        <template v-if="index == 0">
          <rep-tree-modal :downlineList="downlineList" />
        </template>
      </template>
      <template v-if="showMessage">
        <message-content
            :message="showMessage"
            type="sent"
            @onSuccess="getAll"
        />
        <!--        <message-mobile-content
                :message="showMessage"
                type="sent"
                @onSuccess="getAll" />-->
      </template>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import MessageList from "../partials/MessageList";
import SendMessageCompose from "./SendMessageCompose.vue";
import MessageContent from "../partials/MessageContent.vue";
import RepTreeModal from "@/components/RepTree/RepTreeModal.vue";
// import MessageMobileContent from "@/views/Message/partials/MessageMobileContent.vue";
import searchFilter from "../partials/search-filter.vue";

export default {
  name: "SendMessage",
  components: {
    MessageList,
    MessageContent,
    SendMessageCompose,
    RepTreeModal,
    // MessageMobileContent,
    searchFilter
  },
  props: {
    index: {
      required: false,
    },
  },
  data: () => ({
    rows: [],
    canCompose: false,
    showMessage: null,
    downlineList: {},
    searchQuery: null
  }),
  methods: {
    downlineListHandler(downlineList) {
      this.downlineList = downlineList;
    },
    searchMessageList (data) {
      let app = this
      app.searchQuery = data
      app.getAll(app.searchQuery)
    },
    composeMessage() {
      this.canCompose = true;
      if (this.showMessage) {
        document
            .querySelector(`.message-${this.showMessage.mid}-list`)
            .classList.remove("active-list");
      }
      this.showMessage = null;
    },
    getMessage(item) {
      this.canCompose = false;
      this.showMessage = item;
    },
    getAll(searchQuery) {
      if (this.index == 0) {
        let url = "v2/get-sent-message-list";
        if (searchQuery) {
          const encodedQuery = encodeURIComponent(searchQuery);
          url += `?search=${encodedQuery}`;
        }
        let loader = this.$loading.show();
        Api.get(url)
            .then((res) => {
              this.rows = res.data;
              this.showMessage = this.rows.records[0];
              if (this.rows.records.length < 1) {
                this.canCompose = true;
                this.showMessage = null;
              } else {
                this.canCompose = false;
              }
              if (this.index) {
                this.canCompose = true;
              }
            })
            .catch(() => {
              this.rows = []
              this.showMessage = null;
            })
            .finally(() => {
              loader.hide();
            });
      }
    },
  },
  mounted() {
    this.$root.$on("refreshMessage", (data) => {
      if (!data) {
        this.getAll();
      }
    });
  },
  created() {
    this.getAll();
  },
  watch: {
    rows: function (newData, oldData) {
      if (newData != oldData) {
        this.showMessage =
            newData.records.length > 0 ? newData.records[0] : null;
      }
    },
    index: function (newVal, oldVal) {
      if (newVal != oldVal && newVal == 0) {
        this.getAll();
      }
    }
  },
};
</script>
<style>
.receiveMessage-detailcard {
  position: relative;
}
.receiveMessage-detailcard .recieved-actions {
  margin-top: -8px;
}
.receiveMessage-wrap .receiveMessage-detailcard .recieved-actions {
  position: absolute;
  right: 20px;
}
.receiveMessage-detailcard .rcv-msgHead .sndr-head {
  margin-bottom: 10px;
}
.sendMessage-wrap .receiveMessage-detailcard .recieved-actions {
  position: relative;
  margin-bottom: 5px;
}
.sendMessage-wrap .receiveMessage-detailcard .rcv-msgHead .msg-member-list {
  margin-bottom: 20px;
}
.sendMessage-card .msg-list .message-list .msg-userIcon {
  border-radius: 0;
}
.sendMessage-card .msg-list .message-list .msg-userIcon img {
  border-radius: 0;
  margin-left: 5px;
  width: 35px;
  height: 35px;
}
.receiveMessage-detailcard .rcv-msgHead .sndr-head p {
  font-size: 12px !important;
  font-weight: 500;
  color: #ccc;
}
.receiveMessage-detailcard .rcv-msgHead h2,
.receiveMessage-detailcard .rcv-msgHead h3 {
  font-size: 16px;
}
.sendMessage-wrap .page-link {
  padding: 0.375rem 0.3rem;
  font-size: 13px;
}
.sendMessage-wrap .table-pagination {
  margin-top: -20px;
}
</style>
