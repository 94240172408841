<template>
  <div class="page-wrapper msg-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title" style="padding-left:1.2rem">
        <h1>
          Admin Message Center
        </h1>
      </div>
      <b-card no-body>
        <!--<button class="send-email-btn" title="Send Email" @click="$bvModal.show('addMessage')" size="sm" id="link-to-send-email">Send Email</button>-->
        <div class="msg-more-option">
           <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" right no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item href="#" @click="tabIndex = 3">Draft</b-dropdown-item>
            <!--<b-dropdown-item href="#" @click="tabIndex = 3">archive</b-dropdown-item> -->
         </b-dropdown>
        </div>
        <b-tabs card v-model="tabIndex">
          <b-tab title="Sent" @click="tabIndex = 0">
            <b-card-text>
              <send-message :index="tabIndex" v-if="tabIndex == 0" />
            </b-card-text>
          </b-tab>
          <b-tab title="Received" @click="tabIndex = 1" id="link-to-received">
            <b-card-text>
              <receive-message :index="tabIndex" v-if="tabIndex == 1"/>
            </b-card-text>
          </b-tab>
          <b-tab title="Compose" @click="tabIndex = 2" id="link-to-compose">
            <b-card-text>
              <send-message-compose :index="tabIndex"
                                    type="compose"
                                    @renderTabInfo="renderTabIndex" v-if="tabIndex == 2" />
            </b-card-text>
          </b-tab>
          <!--<b-tab title="Compose" @click="tabIndex = 2" id="compose">
            <b-card-text>

            </b-card-text>
          </b-tab>-->
          <b-tab title="Draft" @click="tabIndex = 3"  id="link-to-draft" title-link-class="amc-tab-hide">
            <b-card-text>
              <DraftMessage :index="tabIndex" v-if="tabIndex == 3"/>
            </b-card-text>
          </b-tab>
          <!--<b-tab title="Archive" @click="tabIndex = 3" id="link-to-archive" title-link-class="amc-tab-hide">
            <b-card-text>
              <ArchiveMessage />
            </b-card-text>
          </b-tab> -->
        </b-tabs>
      </b-card>
    </div>
    <add-message />
  </div>
</template>
<script>
import SendMessage from "@/views/Message/SendMessage/SendMessage.vue";
import ReceiveMessage from "@/views/Message/ReceiveMessage/ReceiveMessage.vue";
import DraftMessage from "@/views/Message/DraftMessage/DraftMessage.vue";
import SendMessageCompose from "@/views/Message/SendMessage/SendMessageCompose.vue";
//import ArchiveMessage from "@/views/Message/ArchiveMessage/ArchiveMessage.vue";
import AddMessage from "./AddMessage.vue";
export default {
  name: "AdminMessage",
  components: {
    AddMessage,
    SendMessage,
    ReceiveMessage,
    DraftMessage,
    SendMessageCompose
   // ArchiveMessage,
  },
  data: () => ({
    tabIndex: 0,
    breadcrumbItems: [{ title: "Message Center", active: true }],
  }),
  methods: {
    renderTabIndex(value) {
      if (value === 'saveToDraft') {
        this.tabIndex = 3;
      }
       if (value === 'send') {
        this.tabIndex = 0;
      }
    }
  },
  created() {
    if (
      this.$route.params.selectedReceivedMessage ||
      this.$route.params.tabIndex == 1
    ) {
      this.tabIndex = 1;
    } else {
      this.tabIndex = 0;
    }
  },
};
</script>
<style src="@/assets/css/adminmessage.css"></style>
